// FIXME:
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppointmentI18n, AppointmentType, Currency } from "@/backend/types/product";
import { AppointmentMethod } from "@/backend/types/appointment";

export interface ReservationDetailsSelection {
  appointmentId?: string;
  doctorId: string;
  doctorName: string;
  doctorSpecialities: string[];
  selectedCancerType?: string;
  startTime: string;
  weekday: number;
  minutes: number;
  doctorDuration: number;
  payment: string;
  type: AppointmentType;
  method: AppointmentMethod;
  /**
   * Price, in units of currency, of this appointment at the time it was
   * booked.
   */
  price?: number;
  /**
   * KELA compensation, in unit of currency, that was already deducted from
   * price.
   */
  kelaCompensation?: number;
  /**
   * Reservations variable prices as string array
   */
  calculatedPrices?: string[];
  /**
   * Currency in which the payment was supposedly done.
   *
   * In case of hospital visit, we cannot be sure.  In practice, this is the
   * currency that was "selected" when the booking was made.
   */
  currency?: Currency;
  /**
   * Id prefix is used to get info, whether this is a future meeting or not.
   */
  idPrefix: string;

  i18n: AppointmentI18n;

  status?: number;

  dhReservationId?: string;
}

export const moduleReservationDetails = {
  state: {
    reservationDetailsSelection: null
  },
  mutations: {
    SET_RESERVATION_DETAILS(state: any, item: ReservationDetailsSelection) {
      state.reservationDetailsSelection = item;
      console.debug("Selected: ", item);
    },
    CLEAR_RESERVATION_DETAILS(state: any) {
      state.reservationDetailsSelection = null;
    }
  },
  actions: {},
  getters: {
    getReservationDetails(state: any): ReservationDetailsSelection | null {
      return state.reservationDetailsSelection;
    }
  }
};

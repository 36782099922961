import { AppointmentMethod } from "@/backend/types/appointment";

/**
 * Each appointment sub-category belongs to a category.
 */
export enum AppointmentCategory {
  /**
   * "Syöpätautien lääkärin vastaanotto".
   */
  CANCER_DOCTOR_APPOINTMENT = "CANCER_DOCTOR_APPOINTMENT",
  /**
   * "Terveys- ja hyvinvointipalvelut".
   */
  HEALTH_AND_WELFARE_SERVICES = "HEALTH_AND_WELFARE_SERVICES",
  /**
   * "Kuvantamispalvelut"
   */
  IMAGING_SERVICES = "IMAGING_SERVICES",
  /**
   * "Varhaisen toteamisen palvelut".
   */
  EARLY_DETECTION = "EARLY_DETECTION",
  /**
   * "Erikoislääkärit"
   */
  OTHER_SPECIALISTS = "OTHER_SPECIALISTS",
  /**
   * "Tähystystutkimukset"
   */
  ENDOSCOPIES = "ENDOSCOPIES"
}

/**
 * Each appointment type belong to a sub-category.
 */
export enum AppointmentSubCategory {
  /**
   * Any appointment whose reason code is one of the "L1 reasons".
   *
   * I.e. any "ensikäynti".
   */
  FIRST_APPOINTMENT = "FIRST_APPOINTMENT",

  /**
   * Any appointment whose reason code is one of the "L2 reasons".
   *
   * I.e. any "seurantakäynti".
   */
  FOLLOWUP_APPOINTMENT = "FOLLOWUP_APPOINTMENT",

  // Health and welfare services
  PHYSIOTHERAPY = "PHYSIOTHERAPY",
  NUTRITION_THERAPY = "NUTRITION_THERAPY",
  SEXUAL_COUNSELING = "SEXUAL_COUNSELING",
  UROTHERAPY = "UROTHERAPY",
  PSYCHOLOGY_APPOINTMENT = "PSYCHOLOGY_APPOINTMENT",

  // Imaging services
  MAMMOGRAPHY = "MAMMOGRAPHY",
  CT_SCAN = "CT_SCAN",
  MRI_SCAN = "MRI_SCAN",

  // Early detection
  BOWEL_CANCER_EARLY_DETECTION = "BOWEL_CANCER_EARLY_DETECTION",
  PROSTATE_CANCER_EARLY_DETECTION = "PROSTATE_CANCER_EARLY_DETECTION",
  MOLE_SCREENING = "MOLE_SCREENING",

  // OTHER-SPECIALISTS
  UROLOGY_PRIMARY = "UROLOGY_PRIMARY",
  UROLOGY_CONTROL = "UROLOGY_CONTROL",
  DERMATOLOGY_PRIMARY = "DERMATOLOGY_PRIMARY",
  DERMATOLOGY_CONTROL = "DERMATOLOGY_CONTROL",
  GASTROENTEROLOGY_PRIMARY = "GASTROENTEROLOGY_PRIMARY",
  GASTROENTEROLOGY_CONTROL = "GASTROENTEROLOGY_CONTROL",
}

/**
 * Appointment types.
 *
 * Some of these we sell in MyDocrates, others just support appointments that
 * are made directly to Acute.
 */
export enum AppointmentType {
  // Comprehensive first appointments

  /**
   * Comprehensive first appointment, >90min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Kattava ensikäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  COMPREHENSIVE_FIRST_APPOINTMENT_LONG = "COMPREHENSIVE_FIRST_APPOINTMENT_LONG",
  /**
   * Comprehensive first appointment, >=60min but <=90min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Kattava ensikäynti (60 min)",
   * regardless how long the duration is in Acute calendar.
   * The duration that backend returns in appointment listing is 60.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  COMPREHENSIVE_FIRST_APPOINTMENT = "COMPREHENSIVE_FIRST_APPOINTMENT",
  /**
   * Comprehensive first appointment, >45min but <60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Kattava ensikäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  COMPREHENSIVE_FIRST_APPOINTMENT_SHORT = "COMPREHENSIVE_FIRST_APPOINTMENT_SHORT",

  // Consultation first appointments

  /**
   * Consultation first appointment, >=30min but <=45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Neuvontakäynti (30 min)",
   * regardless how long the duration is in Acute calendar.
   * The duration that backend returns in appointment listing is 30.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  CONSULTATION_FIRST_APPOINTMENT = "CONSULTATION_FIRST_APPOINTMENT",
  /**
   * Consultation first appointment, <30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Neuvontakäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  CONSULTATION_FIRST_APPOINTMENT_SHORT = "CONSULTATION_FIRST_APPOINTMENT_SHORT",

  // Follow-up appointments

  /**
   * Follow-up (a.k.a. control) appointment, >45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seurantakäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  FOLLOWUP_APPOINTMENT_LONG = "FOLLOWUP_APPOINTMENT_LONG",
  /**
   * Follow-up (a.k.a. control) appointment, >30min but <=45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seurantakäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  FOLLOWUP_APPOINTMENT_45 = "FOLLOWUP_APPOINTMENT_45",
  /**
   * Follow-up (a.k.a. control) appointment, exactly 30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seurantakäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  FOLLOWUP_APPOINTMENT_30 = "FOLLOWUP_APPOINTMENT_30",
  /**
   * Follow-up (a.k.a. control) appointment, <30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seurantakäynti (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointment listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  FOLLOWUP_APPOINTMENT_SHORT = "FOLLOWUP_APPOINTMENT_SHORT",

  // Health and welfare services

  /**
   * Physiotherapy appointment, >60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Fysioterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  PHYSIOTHERAPY_LONG = "PHYSIOTHERAPY_LONG",
  /**
   * Physiotherapy appointment, 60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Fysioterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  PHYSIOTHERAPY_60 = "PHYSIOTHERAPY_60",
  /**
   * Physiotherapy appointment, <60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Fysioterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  PHYSIOTHERAPY_SHORT = "PHYSIOTHERAPY_SHORT",

  /**
   * Nutrition therapy appointment, >60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Ravitsemusterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  NUTRITION_THERAPY_LONG = "NUTRITION_THERAPY_LONG",
  /**
   * Nutrition therapy appointment, 60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Ravitsemusterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  NUTRITION_THERAPY_60 = "NUTRITION_THERAPY_60",
  /**
   * Nutrition therapy appointment, <60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Ravitsemusterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  NUTRITION_THERAPY_SHORT = "NUTRITION_THERAPY_SHORT",

  /**
   * Sexual counseling appointment, >60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seksuaalineuvonta (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  SEXUAL_COUNSELING_LONG = "SEXUAL_COUNSELING_LONG",
  /**
   * Sexual counseling appointment, 60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seksuaalineuvonta (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  SEXUAL_COUNSELING_60 = "SEXUAL_COUNSELING_60",
  /**
   * Sexual counseling appointment, <60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Seksuaalineuvonta (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  SEXUAL_COUNSELING_SHORT = "SEXUAL_COUNSELING_SHORT",

  /**
   * Urotherapy appointment, >60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Uroterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  UROTHERAPY_LONG = "UROTHERAPY_LONG",
  /**
   * Urotherapy appointment, 60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Uroterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  UROTHERAPY_60 = "UROTHERAPY_60",
  /**
   * Urotherapy appointment, <60min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Uroterapia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  UROTHERAPY_SHORT = "UROTHERAPY_SHORT",

  /**
   * Mammography appointment, >30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Mammografia ja uä (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  MAMMOGRAPHY_LONG = "MAMMOGRAPHY_LONG",
  /**
   * Mammography appointment, 10-30 min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Mammografia ja uä (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  MAMMOGRAPHY_20 = "MAMMOGRAPHY_20",
  /**
   * Mammography appointment, <10min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Mammografia ja uä (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * This appointment type cannot be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  MAMMOGRAPHY_SHORT = "MAMMOGRAPHY_SHORT",

  /**
   * CT scan appointment, 30 min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Tietokonetomografia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * Only 30 minutes types can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  CT_SCAN_LONG = "CT_SCAN_LONG",
  CT_SCAN_30 = "CT_SCAN_30",
  CT_SCAN_SHORT = "CT_SCAN_SHORT",
  CT_SCAN_BODY_LONG = "CT_SCAN_BODY_LONG",
  CT_SCAN_BODY_30 = "CT_SCAN_BODY_30",
  CT_SCAN_BODY_SHORT = "CT_SCAN_BODY_SHORT",
  CT_SCAN_STOMACH_LONG = "CT_SCAN_STOMACH_LONG",
  CT_SCAN_STOMACH_30 = "CT_SCAN_STOMACH_30",
  CT_SCAN_STOMACH_SHORT = "CT_SCAN_STOMACH_SHORT",
  CT_SCAN_THORAX_LONG = "CT_SCAN_THORAX_LONG",
  CT_SCAN_THORAX_30 = "CT_SCAN_THORAX_30",
  CT_SCAN_THORAX_SHORT = "CT_SCAN_THORAX_SHORT",

  /**
   * MRI scan appointment, 60 min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Magneettitutkimus (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * Only 60 minutes types can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  MRI_SCAN_LONG = "MRI_SCAN_LONG",
  MRI_SCAN_60 = "MRI_SCAN_60",
  MRI_SCAN_SHORT = "MRI_SCAN_SHORT",
  MRI_SCAN_BREASTS_LONG = "MRI_SCAN_BREASTS_LONG",
  MRI_SCAN_BREASTS_60 = "MRI_SCAN_BREASTS_60",
  MRI_SCAN_BREASTS_SHORT = "MRI_SCAN_BREASTS_SHORT",
  MRI_SCAN_PROSTATE_LONG = "MRI_SCAN_PROSTATE_LONG",
  MRI_SCAN_PROSTATE_60 = "MRI_SCAN_PROSTATE_60",
  MRI_SCAN_PROSTATE_SHORT = "MRI_SCAN_PROSTATE_SHORT",

  /**
   * FIT-test, so not actual appointment, but for consistency, used this way.
   *
   * This test type can be reserved in MyDocrates.
   *
   * This test type can be paid in MyDocrates.
   */
  FIT_TEST = "FIT_TEST",
  /**
   * PSA-test, so not actual appointment, but for consistency, used this way.
   *
   * This test type can be reserved in MyDocrates.
   *
   * This test type can be paid in MyDocrates.
   */
  PSA_TEST = "PSA_TEST",
  /**
   * Urology primary appointment, 30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Urologia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * 30 minutes type can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  UROLOGY_PRIMARY_LONG = "UROLOGY_PRIMARY_LONG",
  UROLOGY_PRIMARY_30 = "UROLOGY_PRIMARY_30",
  UROLOGY_PRIMARY_SHORT = "UROLOGY_PRIMARY_SHORT",
  /**
   * Urology control appointment, 30min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Urologia (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * 30 minutes type can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  UROLOGY_CONTROL_LONG = "UROLOGY_CONTROL_LONG",
  UROLOGY_CONTROL_30 = "UROLOGY_CONTROL_30",
  UROLOGY_CONTROL_SHORT = "UROLOGY_CONTROL_SHORT",
  /**
   * Dermatology primary appointment, 45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Ihotautilääkäri (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * 45 minutes type can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  DERMATOLOGY_PRIMARY_LONG = "DERMATOLOGY_PRIMARY_LONG",
  DERMATOLOGY_PRIMARY_20 = "DERMATOLOGY_PRIMARY_20",
  DERMATOLOGY_PRIMARY_SHORT = "DERMATOLOGY_PRIMARY_SHORT",
  /**
   * Dermatology primary appointment, 45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Ihotautilääkäri (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * 45 minutes type can be reserved in MyDocrates.
   *
   * This appointment type cannot be paid in MyDocrates.
   */
  DERMATOLOGY_CONTROL_LONG = "DERMATOLOGY_CONTROL_LONG",
  DERMATOLOGY_CONTROL_20 = "DERMATOLOGY_CONTROL_20",
  DERMATOLOGY_CONTROL_SHORT = "DERMATOLOGY_CONTROL_SHORT",

  /**
   * Psychology appointment, 45min in Acute calendar.
   *
   * This should be shown in MyDocrates as "Psykologin vastaanotto (xx min)",
   * where "xx" is the same duration as in Acute calendar.
   * Backend returns the duration in appointments listing.
   *
   * Only 45 minutes type can be reserved in MyDocrates.
   *
   * This appointment type can be paid in MyDocrates.
   */
  PSYCHOLOGY_APPOINTMENT_LONG = "PSYCHOLOGY_APPOINTMENT_LONG",
  PSYCHOLOGY_APPOINTMENT_45 = "PSYCHOLOGY_APPOINTMENT_45",
  PSYCHOLOGY_APPOINTMENT_SHORT = "PSYCHOLOGY_APPOINTMENT_SHORT",

  GASTROSCOPY_SHORT = "GASTROSCOPY_SHORT",
  GASTROSCOPY_30 = "GASTROSCOPY_30",
  GASTROSCOPY_LONG = "GASTROSCOPY_LONG",

  COLONOSCOPY_SHORT = "COLONOSCOPY_SHORT",
  COLONOSCOPY_60 = "COLONOSCOPY_60",
  COLONOSCOPY_LONG = "COLONOSCOPY_LONG",

  MOLE_SCREENING_30 = "MOLE_SCREENING_30",

  GASTROENTEROLOGY_PRIMARY_30 = "GASTROENTEROLOGY_PRIMARY_30",
  GASTROENTEROLOGY_CONTROL_30 = "GASTROENTEROLOGY_CONTROL_30",

  MEHILAINEN_APPOINTMENT = "MEHILAINEN_APPOINTMENT",
}

/**
 * Supported currencies.
 */
export enum Currency {
  EUR = "EUR"
}

export interface CalculatedPrice {
  /**
   * The price of the sold appointment in the currency.
   */
  price: number | null;
  /**
   * KELA compensation that is already deducted from the price.
   */
  kelaCompensation: number | null;
  /**
   * Price + KELA compensation.  Not currently shown anywhere.
   */
  grossPrice: number | null;
}

/**
 * Product is a combination of method, type, and currency.
 * When those three are known, we get the price and KELA compensation.
 */
export interface Product {
  /**
   * Sold appointment type.
   */
  type: AppointmentType;
  /**
   * Contact method for the sold appointment.
   */
  method: AppointmentMethod;
  /**
   * Payment currency.
   */
  currency: Currency;
  /**
   * CalculatedPrice containing price, kelaCompensation and grossPrice
   */
  calculatedPrice: CalculatedPrice;
  /**
   * Array of CalculatedPrices containing variable prices
   */
  calculatedPrices: CalculatedPrice[];
  /**
   * Inclusive date from which forward this product is available.
   */
  effectiveFrom: string;
}

export interface AppointmentI18n {
  reasonText: {
    fi: string;
    en?: string;
    sv?: string;
  }
  appointmentDescription: {
    fi: string;
    en?: string;
    sv?: string;
  }
}


import axios, { AxiosError, AxiosInstance } from "axios";
import * as rax from "retry-axios";
import {
  AppointmentCancelResponse,
  AppointmentRequest,
  AppointmentResponse,
  AvailableTimesResponse,
  AvailableTimesResponseV2
} from "@/backend/types/appointment";
import {
  AccessToken,
  BasicHttpBody,
  HttpResponse,
  HttpStatus
} from "@/backend/types/response";
import {
  AuthMethod,
  FrontendState,
  FrontendStateResponse,
  LoginResponse
} from "@/backend/types/login";
import {
  ConsentActionRequest,
  ConsentActionResponse,
  ContactUpdateRequest,
  ContactUpdateResponse,
  RegistrationRequest,
  RegistrationResponse
} from "@/backend/types/registration";
import {
  MyAppointmentsResponse,
  MyCarePlansResponse
} from "@/backend/types/reservation";
import {
  AuthorizationsRequest,
  AuthorizationsResponse,
  ExistingAuthorizationsResponse
} from "@/backend/types/authorization";
import { SignedResponse, SigningResponse } from "./types/signing";
import {
  LatestTreatmentHistory,
  SaveTreatmentHistoryRequest,
  SaveTreatmentHistoryResponse
} from "@/backend/types/treatment-history";
import {
  LatestAnamnesis,
  SaveAnamnesisRequest,
  SaveAnamnesisResponse
} from "@/backend/types/anamnesis";
import {
  CheckClientResponse,
  VerifyClientIdRequest,
  VerifyClientIdResponse
} from "@/backend/types/client-existence";
import { KaikuVideoLinkResponse } from "@/backend/types/kaiku";
import { AppointmentType } from "@/backend/types/product";
import { SessionRefreshResponse } from "@/backend/types/session";
import {
  CompleteMultipartUploadRequest,
  CompleteMultipartUploadResponse,
  CreateMultipartUploadRequest,
  CreateMultipartUploadResponse,
  ListUploadsResponse,
  PresignUploadPartRequest,
  PresignUploadPartResponse
} from "@/backend/types/upload";
import { StatusResponse } from "@/backend/types/status";
import { Status } from "@/store/service-state";
import { SupportedLocale } from "@/store/i18n";
import { PaytrailPaymentStatusResponse } from "@/backend/types/paytrail";
import { InsuranceOptionsResponse } from "@/backend/types/insurance";
import { CognitoService } from "./cognito";
import { AssureStatusResponse } from "@/backend/types/assure";
import { AcuteActiveStatusResponse } from '@/backend/types/acuteactive';

/**
 * MyDocrates API client.
 * <p>
 *     Handles API authentication and the basic stability patterns: timeouts,
 *     back-off, retries, and fallbacks.
 * </p>
 */
export class BackendClient {
  private readonly http: AxiosInstance;
  private token?: AccessToken;

  /**
   * <ul>
   * <li>-1: OFF: don't log anything</li>
   * <li>0: ERROR: shows an error after depleting all retries</li>
   * <li>1: WARN: show error codes before retries, show warning when giving up after retries</li>
   * <li>2: INFO: beginning and ending HTTP exchanges, including retries</li>
   * <li>3: DEBUG: message contents, download progress, token refreshes, retry logic</li>
   * <li>4: TRACE: error objects; very verbose</li>
   * </ul>
   */
  public verbosity = 2; // 0 errors, 1 warnings, 2 info, 3 debug, 4 trace

  /**
   * Get the base URL for MyDocrates API.
   */
  get baseURL(): string | undefined {
    return this.http.defaults.baseURL;
  }

  /**
   * @param baseURL For unit tests. Don't provide value in production code.
   */
  constructor(baseURL?: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    // Initialize Axios with default request configuration
    // which is merged with every request.
    this.http = axios.create({
      // NOTE: Not a real environment variable.  Both in development and production modes,
      //       this is replaced at build time by a hardcoded string.
      //       Note also that the Webpack dev server does not seem to do hot-reloading if you
      //       change the environment or .env.* files: a dev server restart is needed.
      // During tests, the env var is a real thing.
      baseURL: baseURL || process.env.VUE_APP_API_BASE_URL,
      timeout: 30000, // in milliseconds

      // Adding an upload listener for XMLHttpRequest causes ALL requests
      // to require CORS pre-flight requests. So let's not.
      // onUploadProgress(progress) {
      //   self.onUploadProgress(progress);
      // },
      onDownloadProgress(progress) {
        self.onDownloadProgress(progress);
      }
    });

    // Attach retry-axios extension
    this.http.defaults.raxConfig = {
      instance: this.http,

      // retry 429 and any 5xx
      statusCodesToRetry: [
        [HttpStatus.TOO_MANY_REQUESTS, HttpStatus.TOO_MANY_REQUESTS],
        [HttpStatus.INTERNAL_SERVER_ERROR, HttpStatus.GATEWAY_TIMEOUT]
      ],
      backoffType: "static",
      retryDelay: 10000, // in milliseconds

      shouldRetry(err: AxiosError) {
        return self.shouldRetry(err);
      },
      onRetryAttempt(err: AxiosError) {
        self.onRetryAttempt(err);
      }
    };
    /*const interceptorId = */
    rax.attach(this.http);
  }

  /**
   * Check if backend service is ok
   *
   * @param locale A locale that is supported by the status-service.
   * @return Service status
   */
  public checkServiceStatus(locale: SupportedLocale): Promise<StatusResponse> {
    //this.info(`Checking service status (locale=${locale})...`);
    return this.request<void, StatusResponse, StatusResponse>(
      {
        path: `/service/status?locale=${locale}`
      },
      {
        response(response: HttpResponse<StatusResponse>): StatusResponse {
          return {
            ...response.data
          };
        },
        fallback(status?: HttpStatus, networkError?: string): StatusResponse {
          return {
            status: status || networkError || Status.Failed,
            message: {
              title: "",
              description: ""
            }
          };
        }
      }
    );
  }

  /**
   * Fetch first appointment timeslots.
   *
   * If `flat` is `false`, returns the timeslots separated into cancer type
   * groups.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": "CANCER_TYPE_1",
   *             "availableTimesPerDay": [...]
   *         },
   *         {
   *             "cancerType": "CANCER_TYPE_2",
   *             "availableTimesPerDay": [...]
   *         },
   *         ...
   *     ]
   * }
   * ```
   *
   * If `flat` is `true`, returns the timeslots in one cancer type group,
   * where `cancerType` is `null`.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": null,
   *             "availableTimesPerDay": [...]
   *         }
   *     ]
   * }
   * ```
   *
   * Inside each cancer type group, the timeslots are separated into date
   * groups.  Date groups are sorted by date.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     "weekday": 3,
   *                     "dayOfWeek": "WEDNESDAY",
   *                     "date": "2021-12-01",
   *                     "times": [...]
   *                 },
   *                 {
   *                     "weekday": 4,
   *                     "dayOfWeek": "THURSDAY",
   *                     "date": "2021-12-02",
   *                     "times": [...]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ]
   * }
   * ```
   *
   * The timeslots inside each date group is sorted by time.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     ...
   *                     "times": [
   *                         {
   *                             "doctorId": "73",
   *                             "weekday": 3,
   *                             "dayOfWeek": "WEDNESDAY",
   *                             "time": "2021-12-01T08:00:00",
   *                             "duration": 60,
   *                             "patientDuration": 60,
   *                             "maxDurationDoctor": 90,
   *                             "availableMethod": [
   *                                 "Hospital",
   *                                 "Phone",
   *                                 "Video"
   *                             ]
   *                         },
   *                         {
   *                             "doctorId": "72",
   *                             "weekday": 3,
   *                             "dayOfWeek": "WEDNESDAY",
   *                             "time": "2021-12-01T08:15:00",
   *                             "duration": 60,
   *                             "patientDuration": 60,
   *                             "maxDurationDoctor": 90,
   *                             "availableMethod": [
   *                                 "Phone",
   *                                 "Video"
   *                             ]
   *                         },
   *                         ...
   *                     ]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ]
   * }
   * ```
   *
   * Each of the timeslots refers to an oncologist by an ID. The oncologist
   * information is in the `doctors` array.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     ...
   *                     "times": [
   *                         {
   *                             "doctorId": "73",
   *                             ...
   *                         },
   *                         ...
   *                     ]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ],
   *     "doctors": [
   *         {
   *             "doctorId": "73",
   *             "doctorName": "Jaska Jokunen",
   *             "doctorCancerTypes": [
   *                 "ETURAUHASSYOPA_DIAGNOSOITU",
   *                 "KIVESSYOPA"
   *             ]
   *         },
   *         ...
   *     ]
   * }
   * ```
   *
   * Since this API is the first API that is called before the login,
   * this response also contains the products list.
   *
   * ```json
   * {
   *    "availableTimes": ...,
   *    "doctors": ...,
   *    "products": [...]
   * }
   * ```
   *
   * Responds with 200 (OK).  Error responses may be:
   *
   *   * 500 (Internal Server Error) if the Lambda function crashed
   *   * 503 (Service Unavailable) if Acute is in trouble
   *   * 504 (Gateway Timeout) if the Lambda function timed out or couldn't start
   *
   * @param flat Whether to return times for all oncologists in one group.
   * @param locale Get foreign prices if not in Finland.
   */
  public fetchFirstAppointmentTimeslots(
    flat: boolean,
    locale: string
  ): Promise<AvailableTimesResponse> {
    this.info(
      `Fetching first appointment timeslots (flat=${flat}, locale=${locale})...`
    );
    return this.request<void, AvailableTimesResponse, AvailableTimesResponse>(
      {
        path: `/timeslots/first?flat=${flat}&locale=${locale}`
      },
      {
        response(
          response: HttpResponse<AvailableTimesResponse>
        ): AvailableTimesResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AvailableTimesResponse {
          return {
            status: status || networkError || "unknown",
            doctors: [],
            availableTimes: [],
            products: []
          };
        }
      }
    );
  }



  public fetchIsAcuteActive(): Promise<AcuteActiveStatusResponse> {
    this.info("Fetching is acute active...");
    return this.request<
      void,
      AcuteActiveStatusResponse,
      AcuteActiveStatusResponse
    >(
      {
        path: "/service/acute-active",
        auth: true
      },
      {
        response(
          response: HttpResponse<AcuteActiveStatusResponse>
        ): AcuteActiveStatusResponse {
          return {
            status: response.status,
            isAcuteActive: response.data.isAcuteActive
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AcuteActiveStatusResponse {
          return {
            status: status || networkError || "unknown",
            isAcuteActive: true
          };
        }
      }
    );
  }

  /**
   * Fetch followup appointment timeslots.
   *
   * Returns the timeslots separated into two cancer type groups.
   * One group is for prostate cancer, filled with 30 minute timeslots,
   * and the other group is for all other cancer types, filled with 45 minute
   * timeslots.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": "ETURAUHASSYOPA_DIAGNOSOITU",
   *             "availableTimesPerDay": [...]
   *         },
   *         {
   *             "cancerType": null,
   *             "availableTimesPerDay": [...]
   *         },
   *         ...
   *     ]
   * }
   * ```
   *
   * See {@link #fetchFirstAppointmentTimeslots} for what those groups look
   * like, and what else is included in the response (`doctors` and `products`).
   *
   * Responds with 200 (OK).  Error responses may be:
   *
   *   * 500 (Internal Server Error) if the Lambda function crashed
   *   * 503 (Service Unavailable) if Acute is in trouble
   *   * 504 (Gateway Timeout) if the Lambda function timed out or couldn't start
   *
   * @param locale Get foreign prices if not in Finland.
   */
  public fetchFollowupAppointmentTimeslots(
    locale: SupportedLocale
  ): Promise<AvailableTimesResponse> {
    this.info(`Fetching followup appointment timeslots (locale=${locale})...`);
    return this.request<void, AvailableTimesResponse, AvailableTimesResponse>(
      {
        path: `/timeslots/followup?locale=${locale}`
      },
      {
        response(
          response: HttpResponse<AvailableTimesResponse>
        ): AvailableTimesResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AvailableTimesResponse {
          return {
            status: status || networkError || "unknown",
            doctors: [],
            availableTimes: [],
            products: []
          };
        }
      }
    );
  }

  /**
   * Fetch timeslots.
   *
   * The `type` parameter is mandatory, and must be one of the appointment types
   * that can be reserved from MyDocrates and support generic timeslots logic:
   *
   *  * `NUTRITION_THERAPY_60`
   *  * `PHYSIOTHERAPY_60`
   *  * `SEXUAL_COUNSELING_60`
   *  * `UROTHERAPY_60`
   *  * `MAMMOGRAPHY_20`
   *
   * If the `type` is missing, or not one of those values,
   * frontend will receive 404 (Not Found) or 400 (Bad Request) response,
   * respectively.
   *
   * The `type` corresponds to a specialist group. E.g. `PHYSIOTHERAPY_60`
   * appointment type corresponds to `PHYSIOTHERAPY` specialist group.
   * In the response, this specialist group is referred to as "cancer type"
   * and the specialists are referred to as "doctors".  These words are
   * obviously inaccurate, and only kept because they were already used in
   * first and follow-up timeslot APIs.
   *
   * The response contains the available appointment methods in the
   * `availableMethods` array.
   *
   * ```json
   * {
   *     "availableMethods": [
   *         "Hospital",
   *         "Phone"
   *     ]
   * }
   * ```
   *
   * The duration of each available timeslot is specified in `duration` and
   * `doctorDuration` properties.  The word "doctor" is a misnomer, but refers
   * to the time the appointment takes on the specialist calendar, whereas
   * the `duration` is what is visible to the patient on MyDocrates and on
   * receipt.
   *
   * ```json
   * {
   *     "duration": 60,
   *     "doctorDuration": 60,
   *     ...
   * }
   * ```
   *
   * The response contains the timeslots in one group,
   * where `cancerType` corresponds to the requested specialist group.
   * The word "cancer type" is not accurate, but refers to a group of
   * specialists.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": "PHYSIOTHERAPY",
   *             "availableTimesPerDay": [...]
   *         }
   *     ]
   * }
   * ```
   *
   * Inside the specialist group, the timeslots are separated into date
   * groups.  Date groups are sorted by date.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     "weekday": 3,
   *                     "dayOfWeek": "WEDNESDAY",
   *                     "date": "2021-12-01",
   *                     "times": [...]
   *                 },
   *                 {
   *                     "weekday": 4,
   *                     "dayOfWeek": "THURSDAY",
   *                     "date": "2021-12-02",
   *                     "times": [...]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ]
   * }
   * ```
   *
   * The timeslots inside each date group is sorted by time.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     ...
   *                     "times": [
   *                         {
   *                             "doctorId": "73",
   *                             "weekday": 3,
   *                             "dayOfWeek": "WEDNESDAY",
   *                             "time": "2021-12-01T08:00:00",
   *                             "duration": 60,
   *                             "patientDuration": 60,
   *                             "maxDurationDoctor": 90
   *                         },
   *                         {
   *                             "doctorId": "72",
   *                             "weekday": 3,
   *                             "dayOfWeek": "WEDNESDAY",
   *                             "time": "2021-12-01T08:15:00",
   *                             "duration": 60,
   *                             "patientDuration": 60,
   *                             "maxDurationDoctor": 90
   *                         },
   *                         ...
   *                     ]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ]
   * }
   * ```
   *
   * Each of the timeslots refers to a specialist by an ID. The specialist
   * information is in the `doctors` array.  The word "doctor" is not accurate.
   *
   * ```json
   * {
   *     "availableTimes": [
   *         {
   *             "cancerType": ...,
   *             "availableTimesPerDay": [
   *                 {
   *                     ...
   *                     "times": [
   *                         {
   *                             "doctorId": "73",
   *                             ...
   *                         },
   *                         ...
   *                     ]
   *                 },
   *                 ...
   *             ]
   *         }
   *     ],
   *     "doctors": [
   *         {
   *             "doctorId": "73",
   *             "doctorName": "Jaska Jokunen",
   *             "doctorCancerTypes": [
   *                 "ETURAUHASSYOPA_DIAGNOSOITU",
   *                 "KIVESSYOPA"
   *             ]
   *         },
   *         ...
   *     ]
   * }
   * ```
   * Since this API is one of the first APIs called before the login,
   * this response also contains the product information.
   *
   * ```json
   * {
   *    "availableTimes": ...,
   *    "doctors": ...,
   *    "products": [...]
   * }
   * ```
   *
   * Responds with 200 (OK).  Error responses may be:
   *
   *   * 400 (Bad Request) if `type` is not as specified above
   *   * 404 (Not Found) if `type` is missing
   *   * 500 (Internal Server Error) if the Lambda function crashed
   *   * 503 (Service Unavailable) if Acute is in trouble
   *   * 504 (Gateway Timeout) if the Lambda function timed out or couldn't start
   *
   * Frontend should retry on 5xx status codes.
   *
   * @param type Reservable and generic appointment type.
   * @param locale Get foreign prices if not in Finland.
   */
  public fetchTimeslots(
    type: AppointmentType,
    locale: string
  ): Promise<AvailableTimesResponseV2> {
    this.info(`Fetching timeslots for ${type} (locale=${locale})...`);
    return this.request<
      void,
      AvailableTimesResponseV2,
      AvailableTimesResponseV2
    >(
      {
        path: `/timeslots/${type}?locale=${locale}`
      },
      {
        response(
          response: HttpResponse<AvailableTimesResponseV2>
        ): AvailableTimesResponseV2 {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AvailableTimesResponseV2 {
          return {
            status: status || networkError || "unknown",
            duration: 0,
            doctorDuration: 0,
            doctors: [],
            availableMethods: [],
            availableTimes: [],
            products: []
          };
        }
      }
    );
  }

  /**
   * Start Signicat authentication flow.
   *
   * The returned string is the URL that needs to be opened.
   * If saving the state fails, string "error" is returned as the location.
   *
   * @param authMethod The user chosen authentication method.
   * @param locale The locale of the Signicat identification UI
   * @param body Data that needs to survive over the login process.
   */
  public saveLoginState(
    authMethod: AuthMethod,
    locale: string,
    body: FrontendState
  ): Promise<FrontendStateResponse> {
    this.info("Saving frontend state to backend...");
    return this.request<
      FrontendState,
      FrontendStateResponse,
      FrontendStateResponse
    >(
      {
        method: "POST",
        path: `/login/state?authMethod=${authMethod}&locale=${locale}`,
        body
      },
      {
        response(
          response: HttpResponse<FrontendStateResponse>
        ): FrontendStateResponse {
          return {
            status: response.status,
            location: response.headers["location"]
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): FrontendStateResponse {
          return {
            status: status || networkError || "unknown",
            location: ""
          };
        }
      }
    );
  }


  /**
   * Cognito authentication requires as it's behind AWS API Gateway Authorizer
   */
  public async initiatePassportValidation(): Promise<FrontendStateResponse> {
    const path = `/verification/start`;

    const cognito = new CognitoService();
    const idToken = await cognito.getCurrentUserIdToken();
    
    const cognitoToken = {
      accessToken: idToken,
      expiresIn: 300,
      tokenType: 'Bearer'
    };
    return this.request({
      method: "POST",
      path: path,
      auth: !!cognitoToken,
      customAuthToken: cognitoToken
      },
    {
      // FIXME conmplains about <any>, currently there isn't matching data format for what backend gives
      // eslint-disable-next-line
      response(res: HttpResponse<any>): FrontendStateResponse {
        return {
          status: res.status,
          location: res.data.url
        };
      },
      fallback(status?: HttpStatus, networkError?: string): FrontendStateResponse {
        return {
          status: status || networkError || "unknown",
          location: ""
        }
      }
    });
  }

  /**
   * Fetch the saved login state after the Signicat authentication flow.
   *
   * @param stateId State ID
   * @return Data that was saved with {@link saveLoginState}
   */
  public fetchLoginState(stateId: string): Promise<LoginResponse> {
    this.info("Fetching login state...");
    return this.request<void, LoginResponse, LoginResponse>(
      {
        path: `/login/state/${stateId}`
      },
      {
        response(response: HttpResponse<LoginResponse>): LoginResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(status?: HttpStatus, networkError?: string): LoginResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  public async fetchAssureStatus(processId: string | undefined): Promise<AssureStatusResponse> {
    this.info(`Fetching assure process status: ${processId}`)

    const cognito = new CognitoService();
    const idToken = await cognito.getCurrentUserIdToken();
    const cognitoToken = {
      accessToken: idToken, // intentional, API Gateway authorizer requires id token to authenticate
      expiresIn: 900, // idk, probably doesn't matter
      tokenType: 'Bearer'
    }

    return this.request<{ processId: string | undefined }, AssureStatusResponse, AssureStatusResponse>(
      {
        method: 'POST',
        path: `/verification/check`,
        customAuthToken: cognitoToken,
        auth: true,
        body: {
          processId: processId
        }
      }, {
        response(response: HttpResponse<AssureStatusResponse>): AssureStatusResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(status?: HttpStatus, networkError?: string): AssureStatusResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    )
  }

  /**
   * Refresh the session, i.e. reset the expiry timestamp.
   *
   * Responds with 200 (OK).  The `BackendClient` stores the refreshed access
   * token internally, so the caller of this method doesn't have to do anything.
   *
   * Error responses may be:
   *
   *   * 500 (Internal Server Error) if the Lambda function crashed
   *   * 504 (Gateway Timeout) if the Lambda function spent more than 30 seconds
   */
  public refreshSession(): Promise<SessionRefreshResponse> {
    this.info("Refreshing session...");
    return this.request<void, SessionRefreshResponse, SessionRefreshResponse>(
      {
        path: "/session/refresh",
        auth: true
      },
      {
        response(
          response: HttpResponse<SessionRefreshResponse>
        ): SessionRefreshResponse {
          return {
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): SessionRefreshResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Send additional consent actions.
   *
   * @param body
   */
  public takeConsentAction(
    body: ConsentActionRequest
  ): Promise<ConsentActionResponse> {
    this.info("Sending consents...");
    return this.request<
      ConsentActionRequest,
      ConsentActionResponse,
      ConsentActionResponse
    >(
      {
        method: "POST",
        path: "/consents",
        auth: true,
        body
      },
      {
        response(response: HttpResponse<ConsentActionResponse>) {
          return {
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): ConsentActionResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Registers user into system.
   */
  public register(body: RegistrationRequest): Promise<RegistrationResponse> {
    this.debug("Sending registration info...");
    return this.request<
      RegistrationRequest,
      RegistrationResponse,
      RegistrationResponse
    >(
      {
        method: "POST",
        path: "/registration",
        auth: true,
        body
      },
      {
        // This request contains user input, so 400 is expected.
        // If it wasn't listed here, it would go straight to fallback,
        // which drops the validation errors. (400 is non-retryable.)
        expectedStatusCodes: [HttpStatus.CREATED, HttpStatus.BAD_REQUEST],
        response(response: HttpResponse<RegistrationResponse>) {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): RegistrationResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Update user contact info to the backend.
   *
   * @param body Phone, email, etc.
   */
  public updateContact(
    body: ContactUpdateRequest
  ): Promise<ContactUpdateResponse> {
    this.info("Sending contact info...");
    return this.request<
      ContactUpdateRequest,
      ContactUpdateResponse,
      ContactUpdateResponse
    >(
      {
        method: "POST",
        path: "/contact",
        auth: true,
        body
      },
      {
        // This request contains user input, so 400 is expected.
        // If it wasn't listed here, it would go straight to fallback,
        // which drops the validation errors. (400 is non-retryable.)
        expectedStatusCodes: [HttpStatus.OK, HttpStatus.BAD_REQUEST],
        response(
          response: HttpResponse<ContactUpdateResponse>
        ): ContactUpdateResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): ContactUpdateResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Make an appointment.
   *
   * @param body Appointment reservation request details
   */
  public makeAnAppointment(
    body: AppointmentRequest
  ): Promise<AppointmentResponse> {
    this.info("Making an appointment...");
    return this.request<
      AppointmentRequest,
      AppointmentResponse,
      AppointmentResponse
    >(
      {
        method: "POST",
        path: "/my/appointments",
        auth: true,
        body
      },
      {
        response(resp: HttpResponse<AppointmentResponse>): AppointmentResponse {
          return {
            status: resp.status,
            ...resp.data
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AppointmentResponse {
          return {
            status,
            networkError
          };
        }
      }
    );
  }

  /**
   * Fetch the Paytrail payment status.
   *
   * On success, responds with 200 (OK) and the payment status. Possible error
   * codes include:
   *
   * * 401 (UNAUTHORIZED) if the session is not valid.
   * * 424 (FAILED_DEPENDENCY) if the session does not include the Paytrail
   *   transaction ID.  This could mean that the frontend didn't make the
   *   appointment successfully, or that the response did not contain payment
   *   gateway URL, or a previous call to this method already returned "ok" or
   *   "failed" payment status.
   * * 503 (SERVICE_UNAVAILABLE) if the backend is unable to talk to Paytrail
   *   API.
   *
   * General errors related to network calls are also possible.
   */
  public paytrailPaymentStatus(): Promise<PaytrailPaymentStatusResponse> {
    this.info("Fetching Paytrail payment status...");
    return this.request<
      void,
      PaytrailPaymentStatusResponse,
      PaytrailPaymentStatusResponse
    >(
      {
        path: "/my/appointments/paytrail",
        auth: true
      },
      {
        response(
          response: HttpResponse<PaytrailPaymentStatusResponse>
        ): PaytrailPaymentStatusResponse {
          return {
            status: response.status,
            paytrailPaymentStatus: response.data.paytrailPaymentStatus
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): PaytrailPaymentStatusResponse {
          return {
            status: status || networkError || "unknown",
            paytrailPaymentStatus: null
          };
        }
      }
    );
  }

  /**
   * Fetch my appointments.
   * Hooks to AppointmentController.list to get appointments.
   *
   * Returns my appointments, reservations.
   */
  public fetchMyAppointments(): Promise<MyAppointmentsResponse> {
    this.info("Fetching my appointments...");
    return this.request<void, MyAppointmentsResponse, MyAppointmentsResponse>(
      {
        path: "/my/appointments",
        auth: true
      },
      {
        response(
          response: HttpResponse<MyAppointmentsResponse>
        ): MyAppointmentsResponse {
          return {
            status: response.status,
            appointments: response.data.appointments
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): MyAppointmentsResponse {
          return {
            status: status || networkError || "unknown",
            appointments: []
          };
        }
      }
    );
  }

  /**
   * Fetch insurance options for the dropdown
   */
  public fetchInsuranceOptions(): Promise<InsuranceOptionsResponse> {
    this.info("Fetching insurance options...");
    return this.request<
      void,
      InsuranceOptionsResponse,
      InsuranceOptionsResponse
    >(
      {
        path: "/my/appointments/insurance-options",
        auth: true
      },
      {
        response(
          response: HttpResponse<InsuranceOptionsResponse>
        ): InsuranceOptionsResponse {
          return {
            status: response.status,
            insurances: response.data.insurances
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): InsuranceOptionsResponse {
          return {
            status: status || networkError || "unknown",
            insurances: []
          };
        }
      }
    );
  }

  /**
   * Cancel a confirmed appointment.
   *
   * This can be used to cancel an appointment that has been confirmed via
   * MyDocrates, or that has been confirmed directly to Acute (e.g. over the
   * phone).
   *
   * Responds with 200 (OK) in case of success.
   *
   * In case of a network or service error, this already retries 2 or 3 times,
   * before resolving the returned promise. Still, possible error cases include:
   *
   *  * 401 (Unauthorized): session expired (or never logged in)
   *  * 402 (Payment Required): appointment start time is not at least 24h in
   *                            the future
   *  * 403 (Forbidden): appointment belongs to another patient
   *  * 424 (Failed Dependency): appointment is not confirmed
   *  * 503 (Service Unavailable): Acute is in trouble
   *
   * @param appointmentId The numeric Acute reservation ID. E.g. "704806".
   * @return status Either the HTTP status code or a network error from the browser.
   */
  public cancelConfirmedAppointment(
    appointmentId: string
  ): Promise<AppointmentCancelResponse> {
    this.info(`Canceling confirmed appointment ${appointmentId}...`);
    return this.request<void, BasicHttpBody, AppointmentCancelResponse>(
      {
        method: "DELETE",
        path: `/my/appointments/${appointmentId}`,
        auth: true
      },
      {
        response(
          response: HttpResponse<BasicHttpBody>
        ): AppointmentCancelResponse {
          return { status: response.status };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AppointmentCancelResponse {
          return { status: status || networkError || "unknown" };
        }
      }
    );
  }

  /**
   * Fetch purchased care plans.
   */
  public fetchMyCarePlans(): Promise<MyCarePlansResponse> {
    this.info("Fetching my care-plans...");
    return this.request<void, MyCarePlansResponse, MyCarePlansResponse>(
      {
        path: "/my/care-plans",
        auth: true
      },
      {
        response(
          response: HttpResponse<MyCarePlansResponse>
        ): MyCarePlansResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): MyCarePlansResponse {
          return {
            status: status || networkError || "unknown",
            carePlans: []
          };
        }
      }
    );
  }

  /**
   * Save treatment history.
   * See TreatmentHistoryService.groovy
   */
  public saveTreatmentHistory(
    body: SaveTreatmentHistoryRequest
  ): Promise<SaveTreatmentHistoryResponse> {
    this.info("Sending treatment history...");
    return this.request<
      SaveTreatmentHistoryRequest,
      SaveTreatmentHistoryResponse,
      SaveTreatmentHistoryResponse
    >(
      {
        method: "POST",
        path: "/treatment-history",
        auth: true,
        body
      },
      {
        // This request contains user input, so 400 is "expected".
        expectedStatusCodes: [HttpStatus.OK, HttpStatus.BAD_REQUEST],
        response(
          response: HttpResponse<SaveTreatmentHistoryResponse>
        ): SaveTreatmentHistoryResponse {
          return {
            status: response.status,
            constraintViolations: response.data.constraintViolations
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): SaveTreatmentHistoryResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Fetch latest saved treatment history.
   */
  public fetchTreatmentHistory(): Promise<LatestTreatmentHistory> {
    this.info("Fetching treatment history...");
    return this.request<void, LatestTreatmentHistory, LatestTreatmentHistory>(
      {
        path: "/treatment-history",
        auth: true
      },
      {
        response(
          response: HttpResponse<LatestTreatmentHistory>
        ): LatestTreatmentHistory {
          return {
            status: response.status,
            lastModified: response.data.lastModified,
            treatmentHistory: response.data.treatmentHistory
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): LatestTreatmentHistory {
          return {
            status: status || networkError || "unknown",
            lastModified: null,
            treatmentHistory: {
              hasTreatmentHistory: false,
              hasBeenAtDocrates: false,
              hasBeenAtNonDocrates: false,
              sinceWhen: "",
              previousExaminations: "",
              previousTreatments: "",
              previousTreatmentPlaces: "",
              currentlyBeingTreated: false,
              followupTreatmentPlans: "",
              allowDocratesToMakeInquiries: false,
              additionalTopics: ""
            }
          };
        }
      }
    );
  }

  /**
   * Authorize to client's medical records usage.
   */
  public authorizeMedicalRecords(
    body: AuthorizationsRequest
  ): Promise<AuthorizationsResponse> {
    this.info("Sending medical records authorization...");
    return this.request<
      AuthorizationsRequest,
      AuthorizationsResponse,
      AuthorizationsResponse
    >(
      {
        method: "POST",
        path: "/authorizations",
        auth: true,
        body
      },
      {
        // This request contains user input, so 400 is "expected".
        expectedStatusCodes: [HttpStatus.ACCEPTED, HttpStatus.BAD_REQUEST],
        response(
          response: HttpResponse<AuthorizationsResponse>
        ): AuthorizationsResponse {
          return {
            status: response.status,
            signingToken: response.data.signingToken,
            constraintViolations: response.data.constraintViolations
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): AuthorizationsResponse {
          return {
            status: status || networkError || "unknown",
            signingToken: ""
          };
        }
      }
    );
  }

  /**
   * Is Authorization document ready to be signed. When ready, URL is in location.
   *
   * @param signingToken Signing token.
   */
  public authorizationDocReadyToBeSigned(
    signingToken: string
  ): Promise<SigningResponse> {
    this.info("Fetching signing URL...");
    return this.request<void, SigningResponse, SigningResponse>(
      {
        path: `/authorizations/signing/${signingToken}/signingUrl`,
        auth: true
      },
      {
        expectedStatusCodes: [
          HttpStatus.CREATED,
          HttpStatus.ACCEPTED,
          HttpStatus.CONFLICT
        ],
        response(response: HttpResponse<SigningResponse>): SigningResponse {
          return {
            status: response.status,
            location: response.headers["location"],
            preparationError:
              response.status === HttpStatus.CONFLICT
                ? response.data.preparationError
                : null
          };
        },
        fallback(status?: HttpStatus, networkError?: string): SigningResponse {
          return {
            status: status || networkError || "unknown",
            location: "",
            preparationError: null
          };
        }
      }
    );
  }

  /**
   * Is Authorization document signed.
   *
   * @param signingToken Signing token.
   */
  public authorizationDocIsSigned(
    signingToken: string
  ): Promise<SignedResponse> {
    this.info("Fetching signing result...");
    return this.request<void, SignedResponse, SignedResponse>(
      {
        path: `/authorizations/signing/${signingToken}/result`,
        auth: true
      },
      {
        expectedStatusCodes: [
          HttpStatus.OK,
          HttpStatus.ACCEPTED,
          HttpStatus.CONFLICT
        ],
        response(response: HttpResponse<SignedResponse>): SignedResponse {
          return {
            status: response.status,
            signingStatus:
              response.status !== HttpStatus.OK
                ? response.data.signingStatus
                : null
          };
        },
        fallback(status?: HttpStatus, networkError?: string): SignedResponse {
          return {
            status: status || networkError || "unknown",
            signingStatus: null
          };
        }
      }
    );
  }

  /**
   * Fetch already existing authorizations. Return default values in error case.
   */
  public fetchAuthorizations(): Promise<ExistingAuthorizationsResponse> {
    this.info("Fetching authorizations...");
    return this.request<
      void,
      ExistingAuthorizationsResponse,
      ExistingAuthorizationsResponse
    >(
      {
        path: "/authorizations",
        auth: true
      },
      {
        response(
          response: HttpResponse<ExistingAuthorizationsResponse>
        ): ExistingAuthorizationsResponse {
          return {
            status: response.status,
            lastModified: response.data.lastModified,
            authorizations: response.data.authorizations
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): ExistingAuthorizationsResponse {
          return {
            status: status || networkError || "unknown",
            lastModified: "",
            authorizations: {
              allowMarketing: false,
              internalSharing: false,
              referralFeedback: false,
              externalInquiries: false,
              previousTreatmentPlaces: "",
              externalSharing: false,
              extraRestrictions: ""
            }
          };
        }
      }
    );
  }

  /**
   * Save anamnesis.
   */
  public saveAnamnesis(
    body: SaveAnamnesisRequest
  ): Promise<SaveAnamnesisResponse> {
    this.info("Sending anamnesis...");
    return this.request<
      SaveAnamnesisRequest,
      SaveAnamnesisResponse,
      SaveAnamnesisResponse
    >(
      {
        method: "POST",
        path: "/anamnesis",
        auth: true,
        body
      },
      {
        expectedStatusCodes: [HttpStatus.OK, HttpStatus.BAD_REQUEST],
        response(
          response: HttpResponse<SaveAnamnesisResponse>
        ): SaveAnamnesisResponse {
          return {
            status: response.status,
            constraintViolations: response.data.constraintViolations
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): SaveAnamnesisResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Fetch latest saved anamnesis.
   */
  public fetchAnamnesis(): Promise<LatestAnamnesis> {
    this.info("Fetching anamnesis...");
    return this.request<void, LatestAnamnesis, LatestAnamnesis>(
      {
        path: "/anamnesis",
        auth: true
      },
      {
        response(response: HttpResponse<LatestAnamnesis>): LatestAnamnesis {
          return {
            status: response.status,
            lastModified: response.data.lastModified,
            anamnesis: response.data.anamnesis
          };
        },
        fallback(status?: HttpStatus, networkError?: string): LatestAnamnesis {
          return {
            status: status || networkError || "unknown",
            lastModified: null,
            anamnesis: {
              occupation: "",
              height: "",
              weight: "",
              smoking: "No",
              smokingYears: "",
              notSmokingSince: "",
              alcohol: false,
              alcoholDosePerWeek: "",
              cancerInFamily: "",
              malignantTumorOrCancer: false,
              malignantTumorOrCancerDetails: "",
              hypertensionOrCardiovascularDisease: false,
              hypertensionOrCardiovascularDiseaseDetails: "",
              pacemaker: false,
              pacemakerDetails: "",
              type1Diabetes: false,
              type1DiabetesDetails: "",
              type2Diabetes: false,
              type2DiabetesDetails: "",
              thyroidDisease: false,
              thyroidDiseaseDetails: "",
              venousThrombosisOrPulmonaryEmbolism: false,
              venousThrombosisOrPulmonaryEmbolismDetails: "",
              tendencyToBleed: false,
              tendencyToBleedDetails: "",
              lungDisease: false,
              lungDiseaseDetails: "",
              urinaryTractDisease: false,
              urinaryTractDiseaseDetails: "",
              skinDisease: false,
              skinDiseaseDetails: "",
              earDiseaseOrHearingLoss: false,
              earDiseaseOrHearingLossDetails: "",
              gastrointestinalDisease: false,
              gastrointestinalDiseaseDetails: "",
              liverAndPancreaticDisease: false,
              liverAndPancreaticDiseaseDetails: "",
              kidneyDisease: false,
              kidneyDiseaseDetails: "",
              neurologicalDisease: false,
              neurologicalDiseaseDetails: "",
              frequentRecurrentHeadacheOrMigraine: false,
              frequentRecurrentHeadacheOrMigraineDetails: "",
              mentalDisorderOrMentalHealthDisease: false,
              mentalDisorderOrMentalHealthDiseaseDetails: "",
              eyeDisease: false,
              eyeDiseaseDetails: "",
              rheumatoidArthritisOrOtherRheumaticDisease: false,
              rheumatoidArthritisOrOtherRheumaticDiseaseDetails: "",
              musculoskeletalAndConnectiveTissueDisorders: false,
              musculoskeletalAndConnectiveTissueDisordersDetails: "",
              otherIllness: false,
              otherIllnessDetails: "",
              covid19: false,
              covid19Details: "",
              covid19Vaccine: false,
              covid19VaccineDetails: "",
              surgeries: false,
              surgeriesDetails: "",
              foreignObjectsInTheBody: false,
              foreignObjectsInTheBodyDetails: "",
              currentMedication: "",
              currentSupplements: "",
              drugAllergies: "",
              occupationalHealthCare: false,
              occupationalHealthCareProvider: "",
              bloodBorneDiseases: false,
              bloodBorneDiseasesDetails: "",
              mrsaSample: false,
              mrsaSampleDetails: "",
              treatedAtPublicSectorOrAbroadInPast6Months: false,
              workAtHospital: false,
              workAtHospitalDetails: "",
              menstrualEndYear: "",
              childbirthYears: "",
              epill: false,
              epillDetails: "",
              hormoneReplacementTherapy: "No",
              hormoneReplacementTherapySince: "",
              hormoneReplacementTherapyYears: ""
            }
          };
        }
      }
    );
  }

  /**
   * Fetch Kaiku video link for the patient.
   *
   * It is frontend responsibility to check that this is NOT called if patient
   * has not accepted Kaiku consent: Kaiku account will be created if it does
   * not already exist.
   *
   * Response statuses:
   *
   *  * 200 (OK): the response contains the https URL in kaikuVideoLink
   *  * 401 (Unauthorized): Login required.
   *  * 424 (Failed Dependency): Registration required.
   *  * 500 (Internal Server Error): Server side bug.
   *  * 503 (Service Unavailable): Kaiku is in trouble.
   */
  public fetchKaikuVideoLink(): Promise<KaikuVideoLinkResponse> {
    this.info("Fetching Kaiku video link...");
    return this.request<void, KaikuVideoLinkResponse, KaikuVideoLinkResponse>(
      {
        path: "/kaiku/video",
        auth: true
      },
      {
        response(
          response: HttpResponse<KaikuVideoLinkResponse>
        ): KaikuVideoLinkResponse {
          return {
            status: response.status,
            kaikuVideoLink: response.data.kaikuVideoLink
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): KaikuVideoLinkResponse {
          return {
            status: status || networkError || "unknown",
            kaikuVideoLink: null
          };
        }
      }
    );
  }

  /**
   * Create a new multipart upload, with a confirmed appointment as upload context.
   *
   * With empty appointmentId parameter the current user is the context,
   * not the appointment.
   *
   * The response status should be `201` (Created).  If the response status
   * is 5xx, then it is safe to retry.
   *
   * The response body contains a new `uploadId`, which must be used when
   * uploading the parts of the multipart upload.  If the multipart upload is
   * not completed in 24 hours, it will expire and all the parts and database
   * entries related to it will disappear from the backend.
   *
   * @param appointmentId The confirmed appointment ID.
   * @param body Metadata about the to-be-uploaded file.
   */
  public createMultipartUpload(
    appointmentId: string,
    body: CreateMultipartUploadRequest
  ): Promise<CreateMultipartUploadResponse> {
    const path = appointmentId
      ? `/uploads/appointments/${appointmentId}`
      : "/uploads/own-info";
    const logText = appointmentId
      ? `Creating multipart upload for appointment ${appointmentId}...`
      : "Creating multipart upload for own info...";
    this.info(logText);
    return this.request<
      CreateMultipartUploadRequest,
      CreateMultipartUploadResponse,
      CreateMultipartUploadResponse
    >(
      {
        method: "POST",
        path: path,
        auth: true,
        body
      },
      {
        response(
          response: HttpResponse<CreateMultipartUploadResponse>
        ): CreateMultipartUploadResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): CreateMultipartUploadResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Create a new presigned URL for a part upload.
   *
   * Presigned URL gives the frontend permissions to upload the part to S3
   * directly, bypassing the REST API.
   *
   * The response status should be `200` (OK).  If the response status
   * is 5xx, then it is safe to retry.
   *
   * @param uploadId The upload ID from {@link createMultipartUpload}
   * @param partNumber One based part number
   * @param body Metadata about the to-be-uploaded part.
   */
  public presignPartUpload(
    uploadId: string,
    partNumber: number,
    body: PresignUploadPartRequest
  ): Promise<PresignUploadPartResponse> {
    this.info(
      `Presigning upload URL for part ${partNumber} (upload ID: ${uploadId})...`
    );
    return this.request<
      PresignUploadPartRequest,
      PresignUploadPartResponse,
      PresignUploadPartResponse
    >(
      {
        method: "POST",
        path: `/uploads/incomplete/${uploadId}/${partNumber}`,
        auth: true,
        body
      },
      {
        response(
          response: HttpResponse<PresignUploadPartResponse>
        ): PresignUploadPartResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): PresignUploadPartResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Complete a multipart upload.
   *
   * The response status should be `202` (Accepted). If the response status
   * is 5xx, then it is safe to retry.
   *
   * @param uploadId The upload ID from {@link createMultipartUpload}
   * @param body Part numbers and corresponding ETag values.
   */
  public completeMultipartUpload(
    uploadId: string,
    body: CompleteMultipartUploadRequest
  ): Promise<CompleteMultipartUploadResponse> {
    this.info(`Completing multipart upload (upload ID: ${uploadId})...`);
    return this.request<
      CompleteMultipartUploadRequest,
      CompleteMultipartUploadResponse,
      CompleteMultipartUploadResponse
    >(
      {
        method: "POST",
        path: `/uploads/incomplete/${uploadId}`,
        auth: true,
        body
      },
      {
        response(
          response: HttpResponse<CompleteMultipartUploadResponse>
        ): CompleteMultipartUploadResponse {
          return {
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): CompleteMultipartUploadResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Fetch completed multipart uploads, whose upload context is a confirmed
   * appointment.
   *
   * With empty appointmentId parameter, we will fetch all completed uploads
   * of the current user, not the appointment.
   *
   * The response status should be `200` (OK).  If the response status
   * is 5xx, then it is safe to retry.
   *
   * @param appointmentId The confirmed appointment ID.
   */
  public fetchCompletedUploads(
    appointmentId: string
  ): Promise<ListUploadsResponse> {
    const path = appointmentId
      ? `/uploads/appointments/${appointmentId}`
      : "/uploads/own-info";
    const logText = appointmentId
      ? `Fetching completed uploads for appointment ${appointmentId}...`
      : "Fetching completed uploads for own info...";
    this.info(logText);
    return this.request<void, ListUploadsResponse, ListUploadsResponse>(
      {
        method: "GET",
        path: path,
        auth: true
      },
      {
        response(
          response: HttpResponse<ListUploadsResponse>
        ): ListUploadsResponse {
          return {
            status: response.status,
            uploads: response.data.uploads
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): ListUploadsResponse {
          return {
            status: status || networkError || "unknown"
          };
        }
      }
    );
  }

  /**
   * Check if client id is found and matches with client information in the backend.
   * Matched acute patient must have temporary pic.
   *
   * Notice that client information is stored in the backend session so we don't need to
   * pass the client information to the backend in the request.
   *
   * @param body VerifyClientIdRequest contains client id
   * @returns CheckClientResponse
   */
  public checkClientId(
    body: VerifyClientIdRequest
  ): Promise<VerifyClientIdResponse> {
    this.info(`Checking client id existence... ${body.acuteId}`);
    return this.request<
      VerifyClientIdRequest,
      VerifyClientIdResponse,
      VerifyClientIdResponse
    >(
      {
        method: "POST",
        path: `/client-existence/`,
        auth: true,
        body
      },
      {
        response(
          response: HttpResponse<VerifyClientIdResponse>
        ): VerifyClientIdResponse {
          return {
            ...response.data,
            status: response.status
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): VerifyClientIdResponse {
          return {
            status: status || networkError || "unknown",
            clientExists: false
          };
        }
      }
    );
  }

  /**
   * Check if there is one or more patients in acute with logged-in user's
   * surname, birthday and gender. Matched Acute patient must have temporary pic.
   *
   * Notice that client information is stored in the backend session so we don't need to
   * pass the client information to the backend in the request.
   *
   * @returns CheckClientResponse
   */
  public checkClientExistence(): Promise<CheckClientResponse> {
    this.debug(`Checking client existence...`);
    return this.request<void, CheckClientResponse, CheckClientResponse>(
      {
        method: "GET",
        path: `/client-existence/`,
        auth: true
      },
      {
        response(
          response: HttpResponse<CheckClientResponse>
        ): CheckClientResponse {
          return {
            status: response.status,
            clientExists: response.data.clientExists
          };
        },
        fallback(
          status?: HttpStatus,
          networkError?: string
        ): CheckClientResponse {
          return {
            status: status || networkError || "unknown",
            clientExists: false // default to false to prevent navigation to ask for Acute ID
          };
        }
      }
    );
  }

  /**
   * Whether the client thinks we're logged in or not.
   */
  public isLoggedIn(): boolean {
    return this.token !== undefined || CognitoService.isLoggedIn();
  }

  /**
   * Clear the authentication token, if any.
   */
  public logout(): void {
    this.token = undefined;
  }

  /**
   * Shared logic for all HTTP requests.
   *
   * The request is configured with method, path, body, and whether it needs
   * authorization header.
   *
   * For responses, it's optional to set the list of expected status codes.
   * By default, all 2xx codes are treated as expected.  Also, a response
   * transformation can be set.  A fallback provider is mandatory.
   *
   * @param request Request configuration
   * @param responseProvider Maps real responses to desired format,
   *                         or provides fallback response.
   */
  private request<I, R extends BasicHttpBody, O>(
    request: {
      method?: "GET" | "POST" | "DELETE";
      path: string;
      body?: I;
      auth?: boolean;
      customAuthToken?: AccessToken
    },
    responseProvider: {
      expectedStatusCodes?: HttpStatus[];
      response?: (response: HttpResponse<R>) => O;
      fallback: (status?: HttpStatus, networkError?: string) => O;
    }
  ): Promise<O> {
    // Authorization header is not one of the "allowed by default".
    // I.e. adding it causes CORS pre-flight request.
    // So, let's only define it when needed.
    const token = request.customAuthToken || this.token;
    const authorization: Record<string, string> =
      request.auth && token
        ? { Authorization: `${token.tokenType} ${token.accessToken}` }
        : {};
    if (request.auth && !authorization) {
      this.warn(
        "This request is configured to use authorization header, but no token is available"
      );
    }
    if (request.body) {
      this.debug("Request body:", request.body);
    }
    return this.http
      .request<I, HttpResponse<R>>({
        method: request.method || "GET",
        url: request.path,
        headers: {
          ...authorization
        },
        data: request.body,
        validateStatus(status: number) {
          if (responseProvider.expectedStatusCodes) {
            return responseProvider.expectedStatusCodes.includes(status);
          }
          return status >= HttpStatus.OK && status < 300;
        }
      })
      .then(response => {
        // FIXME
        // eslint-disable-next-line
        const responseToken = response.data.token || (response.data as any).loginResponse?.token;
        if (responseToken) {
          this.token = responseToken;
          this.debug(
            `Received new authentication token which expires in ${token?.expiresIn} seconds`
          );
          // Application code doesn't need the token.
          response.data.token = undefined;
        } else if (response.status === HttpStatus.UNAUTHORIZED) {
          this.token = undefined;
        }
        if (response.data) {
          this.debug("Response body:", response.data);
        }
        if (responseProvider.response) {
          return responseProvider.response(response);
        }
        return (response.data as unknown) as O;
      })
      .catch(reason => {
        if (reason?.response?.status == HttpStatus.UNAUTHORIZED) {
          this.token = undefined;
        }
        this.explainErr(reason);
        if (reason?.response?.data) {
          this.debug("Response body:", reason?.response?.data);
        }
        this.error("Failed all retries; using a fallback response");
        return responseProvider.fallback(
          reason?.response?.status,
          reason?.code
        );
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onUploadProgress(progress: any): void {
    this.trace("onUploadProgress", progress);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onDownloadProgress(progress: any): void {
    // null >= 0 is true
    if (progress.total > 0 || progress.total === 0) {
      const total = progress.total;
      const loaded = progress.loaded >= 0 ? progress.loaded : 0;
      const percent = Math.floor((loaded / total) * 100);
      if (!Number.isNaN(percent)) {
        this.debug(`Downloaded ${percent}%`);
      }
    } else {
      this.trace("onDownloadProgress: ", progress);
    }
  }

  /**
   * This is called by rax to determine whether an error should be retried.
   *
   * @param err
   * @private
   */
  private shouldRetry(err: AxiosError): boolean {
    this.explainErr(err);
    const cfg = rax.getConfig(err);
    if (cfg !== undefined) {
      // Always respect max retries
      if (cfg.currentRetryAttempt && cfg.retry) {
        if (cfg?.currentRetryAttempt >= cfg?.retry) {
          this.warn("Max retries reached; giving up");
          return false;
        }
      }
    }
    if (err?.code === "ECONNABORTED") {
      this.info("Connection aborted; not retrying");
      return false;
    }
    if (err?.response?.status && cfg?.statusCodesToRetry) {
      const status = err.response.status;
      for (const statusCodesToRetry of cfg.statusCodesToRetry) {
        // [[100 .. 199], [429 .. 429], [500 .. 599]] --> will retry?
        if (
          status >= statusCodesToRetry[0] &&
          status <= statusCodesToRetry[1]
        ) {
          this.debug(`Status ${status} is retryable`);
          return true;
        }
      }
    }

    const retry = rax.shouldRetryRequest(err);
    this.debug(`Retrying: ${retry}`);
    return retry;
  }

  /**
   * This is called by rax after {@link shouldRetry} returns true.
   *
   * @param err
   * @private
   */
  private onRetryAttempt(err: AxiosError): void {
    // TODO: maybe call an application callback here?
    const cfg = rax.getConfig(err);
    this.info(
      `Going to retry (attempt #${cfg?.currentRetryAttempt}) after a back-off of ${cfg?.retryDelay}ms`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private explainErr(err: any): void {
    if (err?.response) {
      this.warn(`Server responded with ${err.response.status}`);
      this.trace(JSON.stringify(err.response.data, null, 2));
    } else if (err?.code) {
      this.warn(`Got error code ${err.code}`);
    } else {
      this.warn("Unknown error");
      this.trace(JSON.stringify(err, null, 2));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private trace(...data: any[]): void {
    if (this.verbosity >= 4) console.trace(...data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private debug(...data: any[]): void {
    if (this.verbosity >= 3) console.debug(...data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private info(...data: any[]): void {
    if (this.verbosity >= 2) console.info(...data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private warn(...data: any[]): void {
    if (this.verbosity >= 1) console.warn(...data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private error(...data: any[]): void {
    if (this.verbosity >= 0) console.error(...data);
  }
}

<template>
  <meta name="viewport" content="width=device-width" />
  <div v-if="statusBarVisible()" class="status-degraded">
    <b>{{ getStatusTitle }}:</b>
    {{ getStatusDescription }}
  </div>
  <!-- Navbar section. -->
  <div class="navbar" v-if="navBarVisible()">
    <div class="navbar-left">
      <!--Docrates logo -->
      <div class="logo clickable to-main-view" @click="toServiceCategories">
        <img
          v-if="getSelectedLanguage === 'sv'"
          id="docrates"
          :style="getNaviLogoSelectionStyle()"
          src="./assets/Docrates_Cancersjukhus_RGB_horizontal.png"
          alt="Docrates Cancer Sjukhuset"
        />
        <!-- TODO logo is different size idk how to fix -->
        <img
          v-else-if="getSelectedLanguage === 'en'"
          id="docrates"
          :style="getNaviLogoSelectionStyle()"
          src="./assets/Docrates_CancerCenter_RGB_horizontal.png"
          alt="Docrates Cancer Center"
        />
        <img
          v-else
          id="docrates"
          :style="getNaviLogoSelectionStyle()"
          src="./assets/DocratesMehilainen_RGB_vaaka.png"
          alt="Docrates Syöpäsairaala"
        />
      </div>
      <!-- Navigation tabs -->
      <div v-if="isLoggedIn() && isNavigationButtonsVisible()" class="pages">
        <div
          :style="getNaviTabSelectionStyle(TABS.Reservations)"
          @click="toReservations"
          id="navi-bar-reservations"
        >
          <h4
            :style="getNaviTabTextSelectionStyle(TABS.Reservations)"
            class="clickable"
          >
            {{ store.getters.translate("generic-reservations") }}
          </h4>
        </div>
        <div
          v-if="otherServicesMenuVisible"
          :style="getNaviTabSelectionStyle(TABS.OtherServices)"
          @click="toOtherServices"
          id="navi-bar-other-services"
        >
          <h4
            :style="getNaviTabTextSelectionStyle(TABS.OtherServices)"
            class="clickable"
          >
            {{ store.getters.translate("other-services") }}
          </h4>
        </div>
        <div
          :style="getNaviTabSelectionStyle(TABS.PersonalData)"
          @click="toPersonalData"
          id="navi-bar-personal-data"
        >
          <div id="navi-personal-data-div">
            <h4
              :style="getNaviTabTextSelectionStyle(TABS.PersonalData)"
              class="clickable"
            >
              {{ store.getters.translate("personal-data") }}
            </h4>
            <!-- Indication, if 1 of 3 Patient Forms is missing -->
            <div
              v-if="store.getters.getMissingPatientForm.length > 0"
              id="div-navi-dot"
              :style="getNaviTabDotSelectionStyle(TABS.PersonalData)"
            ></div>
          </div>
        </div>
        <div
          v-if="myDocumentsMenuVisible"
          :style="getNaviTabSelectionStyle(TABS.MyDocuments)"
          @click="toMyDocuments"
          id="navi-bar-my-documents"
        >
          <div id="navi-my-documents-div">
            <h4
              :style="getNaviTabTextSelectionStyle(TABS.MyDocuments)"
              class="clickable"
            >
              {{ store.getters.translate("files") }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="store.getters.localeSelected" class="navbar-right">
      <div class="user-and-language">
        <!-- Language selector -->
        <LanguageSelector />
        <span style="width: 20px"></span>
        <!-- Login/logout -->
        <div
          v-if="isLoggedIn() && !menuOpen"
          @click="logout"
          id="navi-bar-logout"
        >
          <h4 class="clickable">
            {{ store.getters.translate("generic-logout") }}
          </h4>
        </div>
        <div v-else-if="!menuOpen" id="navi-bar-login" @click="login">
          <h4 class="clickable">
            {{ store.getters.translate("generic-login") }}
          </h4>
        </div>
        <!-- Logic for visibility depends on screen width -->
        <div id="hamburger-div" @click="menuOpen = !menuOpen">
          <div id="hamburger-single-div"></div>
          <div id="hamburger-single-div"></div>
          <div id="hamburger-single-div"></div>
        </div>
      </div>
    </div>
    <input type="hidden" id="locale-debug" :value="localeStr" />
  </div>
<!--  <span>IS ACUTE ACTIVE: {{ store.getters.isAcuteActive }}</span>-->
  <div class="div-app-content">
    <router-view />
  </div>
  <!-- Hamburger menu section (small viewports) -->
  <div v-if="menuOpen" id="hamburger-menu-div">
    <div class="hamburger-menu-background" @click="menuOpen = false"></div>
    <div class="hamburger-menu">
      <div id="hamburger-menu-close">
        <img
          class="close"
          src="@/assets/close.svg"
          @click="menuOpen = false"
          alt="Close"
        />
      </div>
      <div id="hamburger-menu-functions">
        <div
          :style="getNaviTabSelectionStyle(TABS.Reservations)"
          v-if="isLoggedIn() && isNavigationButtonsVisible()"
          @click="toReservations"
          id="navi-bar-menu-reservations"
        >
          <h4
            :style="getNaviTabTextSelectionStyle(TABS.Reservations)"
            class="clickable"
          >
            {{ store.getters.translate("generic-reservations") }}
          </h4>
        </div>
        <div
          :style="getNaviTabSelectionStyle(TABS.OtherServices)"
          v-if="
            isLoggedIn() &&
              isNavigationButtonsVisible() &&
              otherServicesMenuVisible
          "
          @click="toOtherServices"
          id="navi-bar-menu-other-services"
        >
          <h4
            :style="getNaviTabTextSelectionStyle(TABS.OtherServices)"
            class="clickable"
          >
            {{ store.getters.translate("other-services") }}
          </h4>
        </div>
        <div
          :style="getNaviTabSelectionStyle(TABS.PersonalData)"
          v-if="isLoggedIn() && isNavigationButtonsVisible()"
          @click="toPersonalData"
          id="navi-bar-menu-personal-data"
        >
          <div id="navi-personal-data-div">
            <h4
              :style="getNaviTabTextSelectionStyle(TABS.PersonalData)"
              class="clickable"
            >
              {{ store.getters.translate("personal-data") }}
            </h4>
            <!-- Indication, if one of these timestamps is missing -->
            <div
              v-if="
                !store.getters.getAnamnesisTimestamp ||
                  !store.getters.getSignicatAuthorizationsTimestamp ||
                  !store.getters.getTreatmentHistoryTimestamp
              "
              id="div-navi-dot"
              :style="getNaviTabDotSelectionStyle(TABS.PersonalData)"
            ></div>
          </div>
        </div>
        <div
          :style="getNaviTabSelectionStyle(TABS.MyDocuments)"
          v-if="
            isLoggedIn() &&
              isNavigationButtonsVisible() &&
              myDocumentsMenuVisible
          "
          @click="toMyDocuments"
          id="navi-bar-menu-my-documents"
        >
          <div id="navi-my-documents-div">
            <h4
              :style="getNaviTabTextSelectionStyle(TABS.MyDocuments)"
              class="clickable"
            >
              {{ store.getters.translate("files") }}
            </h4>
          </div>
        </div>
        <!-- Login/logout (hamburger menu) -->
        <div v-if="isLoggedIn()" @click="logout" id="navi-bar-logout">
          <h4 class="clickable">
            {{ store.getters.translate("generic-logout") }}
          </h4>
        </div>
        <div
          v-else-if="store.getters.localeSelected"
          id="navi-bar-login"
          @click="login"
        >
          <h4 class="clickable">
            {{ store.getters.translate("generic-login") }}
          </h4>
        </div>
        <!-- Language selector (hamburger menu) -->
        <LanguageSelector />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import router from "./router";
import { index } from "@/store/index";
import { requiredInject } from "@/util/vue";
import { BackendKey } from "@/backend";
import { AppState } from "./store/app-state";
import { StatusResponse } from "@/backend/types/status";
import { Message, Status } from "@/store/service-state";
import { getSelectedLanguage } from "@/util/commonStoreUtils";
import { NpsPaths } from "@/store/appointment-selection";
import LanguageSelector from "@/components/LanguageSelector.vue";

enum TABS {
  Reservations = "reservations",
  OtherServices = "other-services",
  PersonalData = "personal-data",
  MyDocuments = "my-documents"
}

export default defineComponent({
  name: "App",
  components: {
    LanguageSelector
  },
  setup() {
    return {
      backend: requiredInject(BackendKey)
    };
  },
  data() {
    return {
      store: index,
      menuOpen: false,
      languageMenuOpen: false,
      TABS: TABS,
      serviceStatus: Status.OK,
      serviceStatusTitle: "",
      serviceStatusDescription: ""
    };
  },
  async mounted() {
    console.log("APP IS MOUNTED");
    await this.isAcuteActiveFromBackend();

    this.store.dispatch("initializeI18n", this);

    // 1st state is Service Categories.
    // NOTE: Only set state (no router) here, otherwise Signicat-logic does not work.
    // Exception: When path is incorrect - see below.
    this.store.commit("SET_APP_STATE", AppState.ServiceCategories);

    document.body.style.backgroundColor = "#F4F5F5";

    window.addEventListener("resize", () => (this.menuOpen = false), false);

    // Don't await; let it run on the "background".
    this.checkServiceStatus();

    // For logic to test incorrect path -> redirect to beginning.
    const path = new URL(window.location.toString());

    if (NpsPaths.find(it => path.pathname.includes(it))) {
      // Do not handle NPS routes as "Not Found".
      // This special case is only needed because in preprod environments
      // (develop, staging) we don't have an NPS page, but we direct the visitor
      // to our own app instead/regardless.
      // In production, this is slightly incorrect behaviour: if the user types
      // the NPS path, after our domain name, to the browser location, they will
      // get an empty page instead of /service-categories.
      console.log("NPS page!");
      return;
    }

    let pathFoundFromRouter = false;
    // For possible typos etc in urls, add logging in this loop.
    for (let i = 0; i < router.options.routes.length; i++) {
      if (router.options.routes[i].path === path.pathname) {
        pathFoundFromRouter = true;
        if (path.pathname !== "/") {
          // Direct url views have own lang settings.
          return;
        }
        break;
      }
    }

    // Set initial language here as 0.5s delayed, if found from url.
    const queryString = new URLSearchParams(
      window.location.search.substring(1)
    );
    const lang = queryString.get("lang");
    const login: boolean = queryString.has("login");

    // Routing user directly to login page.
    if (login) {
      const validLanguages = ["fi", "en", "sv"];
      const validCountries = ["FI", "SE", "WW"];

      const locale: string = queryString.get("locale") ?? "fi_FI";
      const match = locale.match(/^([a-z]{2})_([A-Z]{2})$/);

      const lang = match && validLanguages.includes(match[1]) ? match[1] : "fi";
      const country = match && validCountries.includes(match[2]) ? match[2] : "FI";

      // Finalize locale decision here
      const countryForLocale: string = country === "WW" ? "SE" : country;
      const finalLocale: string = lang + "-" + countryForLocale;
      this.store.commit("CHANGE_COUNTRY", country);
      this.store.commit("CHANGE_LOCALE", finalLocale);
      // Set this so won't be shown again.
      this.store.commit("CHANGE_LOCALE_SELECT_STATUS", true);

      // Fin and Swe users
      if (country !== "WW") {
        this.store.commit("SET_APP_STATE", AppState.Identification);
      }
      else {
        this.store.commit("SET_APP_STATE", AppState.PwdLogin);
      }
      // Others
      await router.replace("/");
    }
    else {
      if (lang) {
        window.setTimeout(() => {
          // Using as changed from the menu.
          this.languageMenuOpen = true;
          this.changeLanguage(lang);
        }, 500);
      }
      if (!pathFoundFromRouter) {
        console.error("No router for: " + path.pathname);
        // So redirect to beginning.
        await router.push("/service-categories");
      }
    }
  },
  methods: {
    isLoggedIn(): boolean {
      return this.backend.isLoggedIn();
    },
    async isAcuteActiveFromBackend(): Promise<boolean> {
      const acuteResponse = await this.backend.fetchIsAcuteActive();
      // check from store
      this.store.commit("SET_ACUTE_ACTIVE", acuteResponse.isAcuteActive);
      return acuteResponse.isAcuteActive ?? true;
    },
    statusBarVisible(): boolean {
      // show statusbar only if status is Degraded
      // if status is Failed redirect user to error page
      return this.serviceStatus === Status.Degraded;
    },
    navBarVisible() {
      // uhh so uhh? wtf..
      const appState = this.store.getters.getAppState as AppState;
      return !(
        appState === AppState.AppointmentSearch ||
        appState === AppState.ControlAppointmentSearch ||
        this.store.getters.getInfoOverlay
      );
    },
    isNavigationButtonsVisible(): boolean {
      const appState = this.store.getters.getAppState as AppState;
      //wtf :D
      return !(
        appState === undefined ||
        appState === AppState.Signicat ||
        appState === AppState.Registering ||
        appState === AppState.RegisteringI18n ||
        appState === AppState.AdditionalTreatmentInfo ||
        appState === AppState.Permissions ||
        appState === AppState.KaikuAgree ||
        appState === AppState.OrderTestFinalizeKaiku ||
        appState === AppState.OrderTestLoginKaiku ||
        appState === AppState.Payment ||
        appState === AppState.MissingForms ||
        appState === AppState.CountryAndLanguageInitialization ||
        appState === AppState.Logout
      );
    },
    defaultServiceError(): Message {
      return {
        title: this.store.getters.translate("service-error"),
        description: this.store.getters.translate("service-error-description")
      };
    },
    async handleServiceStatus(service: StatusResponse): Promise<void> {
      const previousServiceStatus = this.store.getters.getServiceStatus?.status;
      switch (service.status) {
        case Status.OK:
        case Status.Degraded:
          this.serviceStatus = service.status;
          this.serviceStatusTitle = service.message?.title;
          this.serviceStatusDescription = service.message?.description;
          this.store.commit("SET_SERVICE_STATUS", {
            status: service.status,
            message: service.message
          });
          if (previousServiceStatus === Status.Failed) {
            // We have recovered from an outage.
            console.info(`Recovering from outage`);
            return this.backToLandingPage();
          }
          return;
        default:
          // Status.Failed, HTTP errors, network errors: logout.
          this.serviceStatus = Status.Failed;
          this.serviceStatusTitle = "";
          this.serviceStatusDescription = "";
          this.store.commit("SET_SERVICE_STATUS", {
            status: Status.Failed,
            message:
              service.message.title !== ""
                ? service.message
                : this.defaultServiceError()
          });
          return this.toServiceError();
      }
    },
    async checkServiceStatus(): Promise<void> {
      const locale = this.store.getters.locale;
      if (!locale) {
        // Postponing service status fetching since locale is not set, yet
        setTimeout(this.checkServiceStatus, 1000);
        return;
      }
      const statusResponse = await this.backend.checkServiceStatus(locale);
      await this.handleServiceStatus(statusResponse);
      // During an outage, use a shorter service status poll interval.
      // Otherwise, use 5 minutes.
      const seconds = this.serviceStatus === Status.Failed ? 5 : 5 * 60;
      setTimeout(this.checkServiceStatus, seconds * 1000);
    },
    async backToLandingPage(): Promise<void> {
      if (this.store.getters.getAppState === AppState.Logout) {
        this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
        await router.push("/");
      }
    },
    async toServiceError(): Promise<void> {
      if (this.menuOpen) this.menuOpen = false;
      if (this.store.getters.getAppState !== AppState.Logout) {
        this.store.commit("SET_APP_STATE", AppState.Logout);
        await router.push("/logout?service-error");
      }
    },
    async logout() {
      this.menuOpen = false;
      this.store.commit("SET_APP_STATE", AppState.Logout);
      await router.push("/logout");
    },
    async login() {
      // direct international customers to login with password
      // swe & fin customers go to bankid or pankkitunnus identification    
      this.menuOpen = false;
      if (this.store.getters.country == "WW") {
        this.store.commit("SET_APP_STATE", AppState.PwdLogin);
      } else {
        this.store.commit("SET_APP_STATE", AppState.Identification);
      }
      await router.push("/");
    },
    async toReservations() {
      this.menuOpen = false;
      this.store.commit("SET_APP_STATE", AppState.Reservations);
      await router.push("/reservations");
    },
    async toOtherServices() {
      this.menuOpen = false;
      this.store.commit("SET_APP_STATE", AppState.OtherServices);
      await router.push("/other-services");
    },
    async toPersonalData() {
      this.menuOpen = false;
      this.store.commit("SET_APP_STATE", AppState.PersonalData);
      await router.push("/personal");
    },
    async toMyDocuments() {
      this.menuOpen = false;
      this.store.commit("SET_APP_STATE", AppState.MyDocuments);
      await router.push("/my-documents");
    },
    async toServiceCategories() {
      this.menuOpen = false;

      // Logic when it is safe to return to Service Categories by clicking logo.
      if (!this.isLoggedIn() || this.isNavigationButtonsVisible()) {
        // At first, clear all selections.
        // User/login related storedata can exist as it is.
        this.store.commit("CLEAR_SELECTIONS");
        this.store.commit("CLEAR_SERVICE_SELECTION");
        this.store.commit("CLEAR_SERVICE_CATEGORY_SELECTION");
        this.store.commit("CLEAR_APPOINTMENT_SELECTION");
        this.store.commit("SET_APP_STATE", AppState.ServiceCategories);
        await router.push("/service-categories");
      }
    },
    isSelectedTab(tab: TABS): boolean {
      if (tab === TABS.Reservations && this.isReservationsTab()) return true;
      if (tab === TABS.OtherServices && this.isOtherServicesTab()) return true;
      if (tab === TABS.PersonalData && this.isPersonalDataTab()) return true;
      if (tab === TABS.MyDocuments && this.isMyDocumentsTab()) return true;
      return false;
    },
    getNaviTabSelectionStyle(tab: TABS) {
      // By default can be empty
      const emptyStyle = {};
      const selectedStyle = {
        background: "linear-gradient(180deg, #00B0B9 0%, #00A0A8 57.81%)",
        "box-shadow": "0px 0px 5px rgba(0, 0, 0, 0.25)",
        "border-radius": "5px",
        "padding-left": "20px",
        "padding-right": "20px",
        "max-height": "42px"
      };
      return this.isSelectedTab(tab) ? selectedStyle : emptyStyle;
    },
    getNaviTabTextSelectionStyle(tab: TABS) {
      const defaultStyle = {
        color: "#00A0A8",
        "margin-top": "24px"
      };
      const selectedStyle = {
        color: "#FFFFFF",
        "margin-top": "8px"
      };
      return this.isSelectedTab(tab) ? selectedStyle : defaultStyle;
    },
    getNaviTabDotSelectionStyle(tab: TABS) {
      // By default can be empty
      const emptyStyle = {};
      const selectedStyle = {
        top: "8px",
        "margin-right": "10px"
      };
      return this.isSelectedTab(tab) ? selectedStyle : emptyStyle;
    },
    isReservationsTab() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.Reservations;
    },
    isOtherServicesTab() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.OtherServices;
    },
    isPersonalDataTab() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.PersonalData;
    },
    isMyDocumentsTab() {
      const appState = this.store.getters.getAppState as AppState;
      return appState === AppState.MyDocuments;
    },
    getNaviLogoSelectionStyle() {
      // By default can be empty
      const emptyStyle = {};
      const selectedStyle = {
        "margin-top": "13px",
        "margin-bottom": "10px"
      };
      return this.menuOpen ? selectedStyle : emptyStyle;
    },
    changeLanguage(language: string) {
      if (this.languageMenuOpen) {
        this.languageMenuOpen = false;
        // Change language from store and country stays as it is.
        const country = this.store.getters.locale.slice(-2);
        const newLocale = language + "-" + country;
        this.store.commit("CHANGE_LOCALE", newLocale);
      }
    }
  },
  computed: {
    getStatusTitle(): string {
      return this.serviceStatusTitle;
    },
    getStatusDescription(): string {
      return this.serviceStatusDescription;
    },
    myDocumentsMenuVisible(): boolean {
      return this.store.getters.country !== 'FI';
    },
    otherServicesMenuVisible(): boolean {
      return this.store.getters.country === 'FI';
    },
    getSelectedLanguage,
    localeStr(): string {
      return this.store.getters.locale;
    }
  }
});
</script>
<style lang="scss">
.clickable {
  cursor: pointer;
}

// Page title & description div
.div-page-content-desc,
.div-page-content-desc-wide {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  padding-left: 30px;
  padding-right: 30px;
  align-self: flex-start;

  h1 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
  }
  sub {
    text-align: left;
    display: inline-block;
    margin-bottom: 50px;
  }
}
.div-page-content-desc-wide {
  max-width: 100%;
  padding: 0px;
}
// "Full wide" Content frame with white background
.div-page-content-area {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  padding: 30px 30px;
  height: fit-content;
  background: $white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  text-align: left;

  .div-content-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;

    h3 {
      text-align: left;
      margin: 15px 0px;
    }
  }
}

.two-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  margin-top: 23px;

  button {
    width: 240px;
  }
}

.one-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  margin-top: 23px;

  button {
    width: 100%;
  }
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
}
.div-app-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
}
body.modal-open {
  overflow: hidden;
}
#app {
  display: flex;
  flex-direction: column;
  font-family: "Gilroy-Regular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100%;
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: $background;
  border-bottom: 1px solid $black10;
  z-index: 999999 !important;
}

.status-degraded {
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $orange;
  border-bottom: 1px solid $black10;
  z-index: 9999999 !important;
  position: -webkit-sticky !important; // for safari
  position: sticky !important;
  top: 0;
  padding: 10px;
  color: $black100;
}

.navbar-left {
  display: flex;
  padding-left: $page-margin;
}

.navbar-right {
  display: flex;
  padding-right: $page-margin;
}

.logo {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 185px;

  img {
    width: 100%;
  }
}
.pages {
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  div {
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-right: 15px;
  }
}

.user-and-language {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  max-height: fit-content;

  div {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

.div-language-selection-main {
  background: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
}
.div-language-selection-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 40px;
  max-width: 600px;
  background: $white;
  border-radius: 10px;
}

@media (max-width: 600px) {
  .div-language-selection-frame {
    margin-left: 5px;
    margin-right: 5px;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 $page-margin;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 950px;
}

.content-narrow {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: $page-max-narrow-width;
  width: 100%;
}

@mixin card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: $white;
  border-radius: 10px;
  padding: 10px 30px 30px 30px;

  @include soft-background-shadow;
}

.content-frame {
  @include card;
  max-width: $page-max-narrow-width;

  .div-frame-content {
    margin: 15px 0;
  }
}

.div-category-selectables-base {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 30px;
  background: $white;
  text-align: left;
  margin-bottom: 30px;
}
.div-category-selectables-heading {
  display: flex;
  flex-direction: row;
}
.div-category-selectables-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
}

.frame-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;

  button {
    width: 47%;
  }
}

#available-times-criteria-body {
  text-align: left;
  margin-top: 20px;
}

#appointment-descrption-sub-1 {
  color: $black90;
}

.div-columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
  max-width: 1000px;
  margin-top: 20px;
  width: 100%;
  column-gap: 50px;
  row-gap: 40px;
}
.left-column {
  max-width: 600px;
  width: 100%;
}
.right-column {
  max-width: 433px;
  justify-items: flex-start;
}
.left-column#left-column-after-time-selection {
  max-width: 513px;
}
.div-search-buttons-after-search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 50px;

  .div-search-buttons-after-search {
    display: flex;
    width: 100%;
    height: fit-content;
    flex-direction: row;
    justify-content: flex-start;
    #div-clear-selections {
      a {
        text-decoration: underline;
      }
    }

    .div-search-button {
      display: flex;
      align-items: center;
      margin-right: 30px;

      a {
        text-decoration: none;
      }

      button {
        display: flex;
        align-items: center;
        margin: 0px;
      }

      img {
        height: 15px;
        width: 15px;
        margin-left: 5px;
        margin-right: 13px;
      }

      img.icon-white {
        height: 20px;
        width: 20px;
      }
    }
  }
  body {
    margin-top: 30px;
    margin-bottom: 0px;
  }
}

.div-selected-time {
  display: flex;
  flex-direction: row;
  max-width: $page-max-width;
  margin-bottom: 30px;
}

.div-after-time-selection-frame {
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  padding: 10px 30px 30px 30px;
  max-width: 513px;
  width: 100%;

  .div-selected-info {
    display: flex;
    width: 100%;
  }
}

.div-times {
  width: 100%;
  border-radius: 10px;
}

#available-times-per-day-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  :first-child {
    margin-top: 0px;
  }
}

#available-times-per-day {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.div-times-main-frame {
  width: 100%;
  max-width: 600px;
}

.div-after-time-selection-info-frame {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  text-align: left;
  width: 100%;
}
.div-no-times {
  display: flex;
  flex-direction: column;
  width: 100%;

  h2 {
    text-align: left;
  }
  h3 {
    margin: 10px 0px;
    color: $black100;
  }
  div {
    margin-bottom: 10px;

    h3 {
      margin: 10px;
      color: $black100;
    }
  }
}

.div-single-day-times {
  @include soft-background-shadow;

  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.div-single-available-time {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

#div-infonote-appointment-main {
  margin-top: 10px;
  max-width: 600px;
}

#div-infonote-appointment-frame {
  background: $white;
  padding: 20px;
}

.div-search-buttons-before-search {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

#appointment-search-transact-before-button {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.faq {
  max-width: $page-max-faq-width;
}

.faq-box {
  margin-bottom: 40px;

  div {
    margin: 5px 0;
  }
}

.link-mehilainen {
  text-decoration: none;
  font-weight: bold;
  color: #007e32;
}

.link-icon-mehilainen {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Ensures aspect ratio remains intact */
  vertical-align: bottom; /* Aligns with text */
  filter: invert(28%) sepia(91%) saturate(953%) hue-rotate(111deg) brightness(97%) contrast(101%);
}

.filter-darker {
  filter: invert(60%) sepia(96%) saturate(5039%) hue-rotate(155deg)
    brightness(95%) contrast(103%);
}
.filter-lighter {
  filter: invert(51%) sepia(86%) saturate(535%) hue-rotate(86deg)
    brightness(99%) contrast(88%);
}
@mixin button-base {
  border-radius: 40px;
  padding: 15px 25px;
  margin: 0;
  border: none;
  outline: none;
  cursor: pointer;
}

@mixin button-primary {
  @include button-base;

  background: $button-gradient;
  color: $white;

  &:hover {
    background: $original;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  }
}

@mixin button-secondary {
  @include button-base;

  border: 2px solid $darker;
  background: $white;
  color: $darker;

  &:hover {
    background: $light-teal;
  }
}

@mixin button-external {
  @include button-base;

  border: 2px solid $kaiku-yellow;
  background: $white;
  color: $black100;

  &:hover {
    background: $white;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  }
}

@mixin button-disabled {
  @include button-base;

  background: $black10;
  color: $black70;
  cursor: disabled;

  &:hover {
    color: $fog;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.buttonReturn {
  @include button-secondary;
}

.buttonContinue {
  @include button-primary;
}
.buttonOpenExt {
  @include button-external;
}
button.buttonReturn:disabled,
button.buttonReturn[disabled] {
  border: 2px solid $black70;
  background-color: $white;
  color: $black70;
}
.buttonContinue {
  border: none;
  border-radius: 40px;
  background-image: $button-gradient;
  min-height: 52px;
  padding: 15px, 25px, 15px, 25px;
  color: $white;
  outline: none;
}
button.buttonContinue:disabled,
button.buttonContinue[disabled] {
  background-image: none;
  background-color: $black10;
  color: $black70;
}
.button-search {
  @include button-primary;

  width: 100%;
}
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./fonts/Gilroy/Gilroy-Bold.otf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url(./fonts/Gilroy/Gilroy-Light.otf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(./fonts/Gilroy/Gilroy-Medium.otf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./fonts/Gilroy/Gilroy-Regular.otf) format("truetype");
}
@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy-Semibold"),
    url(./fonts/Gilroy/Gilroy-Semibold.otf) format("truetype");
}
h1 {
  font-family: "Gilroy-Regular";
  font-size: 48px;
  line-height: 58px;
  color: $darker;
}
h2 {
  font-family: "Gilroy-Regular";
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  line-height: 39.2px;
  color: $darker;
}
h3 {
  font-family: "Gilroy-Semibold";
  font-size: 22px;
  letter-spacing: 0.02em;
}
h4 {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  letter-spacing: 0.04em;
  color: $darker;
  cursor: default;
}
h5 {
  visibility: hidden; // for unit testing purposes
}
sub {
  font-family: "Gilroy-Regular";
  font-size: 22px;
  letter-spacing: 0.02em;
  line-height: 26.4px;
  font-weight: 400;
  font-style: normal;
  color: $black100;
}
body {
  font-family: "Gilroy-Regular";
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 1.5em;
  font-style: normal;
  font-weight: 400;
  color: $black90;
}
b {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  letter-spacing: 0.02em;
  line-height: 100%;
  font-style: normal;
  font-weight: 600;
  color: $black100;
}
small {
  font-family: "Gilroy-Regular";
  font-size: 14px;
  letter-spacing: 0.02em;
  line-height: 1.3em;
}
a {
  font-family: "Gilroy-Regular";
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.02em;
  line-height: 22px;
  color: $darker;
}
pre {
  font-family: "Gilroy-Bold";
  font-size: 14px;
  letter-spacing: 0.12em;
}
button {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  letter-spacing: 0.04em;
}
hr {
  width: 32px;
  height: 0px;
  border: 1px solid $orange;
  margin: 0px 5px 30px;
}
label {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: $darker;
}
input {
  cursor: pointer;
}
select {
  width: 100%;
  color: $black100;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: 0.02em;
  border: 1px solid $background;
  border-radius: 5px;
  padding: 20px 20px;
  filter: grayscale(1);
  appearance: none;
  background: url("./assets/down-rounded.svg") 97% center no-repeat $background !important;
  cursor: pointer;
}
.labelCheckbox {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black90;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  cursor: pointer;
}
.labelCheckboxChecked {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black100;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  cursor: pointer;
}
.labelDisabledCheckboxChecked {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-transform: none;
  color: $black70;
  margin: 0px 0px 0px 10px;
  text-align: left;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  cursor: default;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  color: $black100;
  background: $background;
  border: none;
  border-radius: 5px;
  margin: 5px 15px 20px;
  padding-left: 20px;
  filter: grayscale(1);
  cursor: pointer;
}
.filterRed {
  filter: invert(36%) sepia(92%) saturate(4072%) hue-rotate(353deg)
    brightness(97%) contrast(90%);
  width: 15px;
  height: 15px;
  margin-left: 20px;
  margin-top: 5px;
  margin-right: 5px;
}
.error-icon {
  filter: invert(36%) sepia(92%) saturate(4072%) hue-rotate(353deg)
    brightness(97%) contrast(90%);
  width: 15px;
  height: 15px;
  margin-left: 40px;
  margin-top: 5px;
  margin-right: 5px;
}
::placeholder {
  font-family: "Gilroy-Regular";
  color: $black70;
  font-weight: normal;
}
.icon-darker-return {
  filter: invert(33%) sepia(99%) saturate(1814%) hue-rotate(159deg)
    brightness(100%) contrast(101%);
  width: 16px;
  height: 16px;
  margin-left: 10px;
  margin-right: 10px;
}
#div-navi-dot {
  position: relative;
  width: 20px;
  height: 20px;
  left: 15px;
  top: 24px;
  background: $orange;
  display: flex;
  float: left;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 50%;
}
#div-navi-language {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#div-navi-language-main {
  display: flex;
  flex-direction: row;
  margin-right: 10px;
}
#div-navi-language-item {
  display: flex;
  flex-direction: row;
  margin-top: -30px;
  position: absolute;
}
textarea {
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  min-height: 114px; // 2 * 57
  color: $black100;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  letter-spacing: 0.02em;
  background: $background;
  border: 1px solid $background;
  margin: 0px;
  border-radius: 5px;
  filter: grayscale(1);
  cursor: pointer;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.close {
  cursor: pointer;
  border-radius: 50px;
  margin-top: 40px;
  margin-right: -7px;
  height: 18px;
  padding: 6px;
}

.modal-content {
  margin: 20px 0;
}

.modal-content-element {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  div {
    width: 100%;
  }
  label {
    margin: 0;
    min-width: 200px;
    text-align: left;
  }
}
.infoNote {
  padding-top: 10px;
}
.unit-test {
  display: none;
}

#navi-personal-data-div {
  display: flex;
  flex-direction: row;
}

#hamburger-div {
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

#hamburger-single-div {
  width: 26px;
  height: 2px;
  background-color: $darker;
  margin: 3px 0;
}

#hamburger-menu-div {
  display: flex;
  justify-content: flex-end;
}

.hamburger-menu-background {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.hamburger-menu {
  position: fixed;
  z-index: 1;
  left: 1;
  top: 0;
  height: 100%;
  overflow: scroll;
  background-color: $fog;
  padding: $page-margin;
}

#hamburger-menu-close {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 50px;

  img {
    width: 28px;
  }
}

#hamburger-menu-functions {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // This with text content and paddings defines Menu width.
  // Based on design and usual minimum mobile screen width, 320px.
  // Can also be removed, but looks better this way.
  margin-right: 70px;
}

// For showing appointment label and data.
// In Payment and Reservation Cancel.
.div-appointment-data-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px;
}
.div-appointment-data-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}
.appointment-data-label {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.appointment-data {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: -moz-available; /* WebKit-based browsers will ignore this. */
}

// For Appointment and Category page.
.div-appointment {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  order: 0;
  flex-grow: 0;
  max-width: 950px;
  height: fit-content;
  background: $background;
  text-align: left;
}

#p-after-selectable {
  margin-top: 20px;
}

#registering-country-input {
  cursor: default;
}
.registering-row-input {
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}
.registering-row-input-disabled {
  display: flex;
  justify-content: flex-start;
  cursor: default;
}
#reservation-details-return-h3 {
  cursor: pointer;
}

@media (max-width: 1160px) {
  .navbar {
    #hamburger-div {
      visibility: visible;
    }
    .pages {
      display: none;
    }
    .user-and-language {
      visibility: hidden;
      height: 0px;
    }
  }
}

@media (max-width: 1060px) {
  .div-category-selectables-base {
    max-width: 464px;
  }
  .div-category-selectables-content {
    display: flex;
    flex-direction: column;
  }
  .div-search-buttons-after-search-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 560px;
    justify-content: center;

    .div-search-buttons-after-search {
      display: flex;
      width: 100%;
      flex-direction: column;

      .div-search-button {
        width: 100%;
        margin-right: 0px;
        margin-top: 20px;
        justify-content: center;
        button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
          width: 100%;
        }
        a {
          width: 100%;
        }
      }
      :first-child {
        margin-top: 0px;
      }
    }
  }
  .left-column {
    max-width: 560px;
    width: 100%;
  }
  .right-column {
    max-width: 480px;
    width: 100%;
  }
  .faq {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 1000px) {
  .content {
    max-width: $page-max-narrow-width;
  }

  .faq {
    max-width: $page-max-narrow-width;
  }
}

@media (max-width: 600px) {
  // Page title & description div
  .div-page-content-desc {
    padding-left: 10px;
    padding-right: 10px;
  }
  .div-page-content-area {
    padding: 30px 10px;
  }
  .two-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    margin-top: 23px;

    button {
      width: 100%;
    }
    .buttonContinue {
      margin-top: 15px;
    }
  }
  .left-column#left-column-after-time-selection {
    margin-left: 5px;
    margin-right: 5px;
  }
  .div-after-time-selection-frame {
    padding: 10px;
    margin: 0 auto;
  }
  .div-after-time-selection-info-frame {
    padding: 0px 10px 10px 10px;
  }
  #div-infonote-appointment-frame {
    padding: 10px;
  }
  .div-appointment-data-row {
    display: flex;
    flex-direction: column;
  }
  .appointment-data {
    margin-bottom: 5px;
  }
}

@media (max-width: 560px) {
  .content-wrapper {
    margin: 0px 5px;
  }
  .close {
    padding-top: 24px;
  }
  .left-column {
    max-width: 100%;
  }
  .div-appointment {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    order: 0;
    flex-grow: 0;
    max-width: 950px;
    height: fit-content;
    background: $background;
    text-align: left;
  }
  #appointment-search-change-button,
  #appointment-search-call-button {
    width: 100%;
  }
}

@media (max-width: 449px) {
  .div-page-content-desc {
    h1 {
      font-size: 36px;
    }
  }
}

@media (min-width: 1161px) {
  #hamburger-menu-div {
    visibility: hidden;
  }
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>

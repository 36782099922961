import { BasicHttpBody, HttpStatus } from "@/backend/types/response";
import { AppointmentType, Product } from "@/backend/types/product";
import { SupportedLocale } from "@/store/i18n";

/**
 * Response body JSON for available appointment times API.
 */
export interface AvailableTimesResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * List of doctors who have available appointment times.
   */
  doctors: Doctor[];
  /**
   * Available appointment times, grouped first by cancer type and then by day.
   */
  availableTimes: AvailableTimes[];
  /**
   * All sold products and their prices in each supported currency.
   */
  products: Product[];
}

export interface AvailableTimesResponseV2 extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
  /**
   * The duration of each timeslot.
   *
   * This is what is shown to the patient in MyDocrates frontend,
   * and on the receipt.
   */
  duration: number;
  /**
   * The duration of each timeslot on Acute calendar.
   */
  doctorDuration: number;
  /**
   * The available appointment methods.
   */
  availableMethods: AppointmentMethod[];
  /**
   * List of doctors who have available appointment times.
   */
  doctors: Doctor[];
  /**
   * Available appointment times, grouped first by cancer type and then by day.
   */
  availableTimes: AvailableTimes[];
  /**
   * All sold products and their prices in each supported currency.
   */
  products: Product[];
}

/**
 * Description of a doctor in available appointments response.
 */
export interface Doctor {
  /**
   * Doctor ID.  Opaque string that should not be interpreted.
   */
  doctorId: string;
  /**
   * Full name of the doctor.
   */
  doctorName: string;
  /**
   * List of cancer types this doctor specialized for.
   */
  doctorCancerTypes: string[];
}

/**
 * Available appointment times for a single cancer type.
 */
export interface AvailableTimes {
  /**
   * Cancer type for which these appointment times are available.
   */
  cancerType: string; // enum
  /**
   * The available appointment times for this cancer type.
   */
  availableTimesPerDay: AvailableTimesPerDay[];
}

/**
 * Available appointment times for a cancer type and for a single day.
 */
export interface AvailableTimesPerDay {
  /**
   * Day of week: 1 is Monday, 7 is Sunday.
   */
  weekday: number;
  /**
   * Date in format "dd-MM-YYYY".
   */
  date: string;
  /**
   * Available appointment types for this day.
   */
  times: AvailableTime[];
}

/**
 * A single available appointment time.
 */
export interface AvailableTime {
  /**
   * Day of week: 1 is Monday, 7 is Sunday.
   */
  weekday: number;
  /**
   * Date and time in format "dd-MM-YYYYThh:mm".
   */
  time: string;
  /**
   * Duration of this appointment, in minutes.
   */
  patientDuration: number;
  /**
   * Number of minutes this appointment takes from doctor.
   */
  maxDurationDoctor: number;
  /**
   * Doctor ID, which references the doctor in {@link AvailableTimesResponse#doctors} list.
   */
  doctorId: string;
  /**
   * The available appointment methods.
   *
   * Currently, this is defined only for cancer doctor appointment types:
   * first and followup appointments.
   */
  availableMethods: AppointmentMethod[];
}

export enum AppointmentMethod {
  Video = "Video",
  Phone = "Phone",
  Hospital = "Hospital",
  NotApplicable = "NotApplicable"
}

export enum InsuranceCompany {
  /**
   * Patient indicated that they don't have an insurance.
   */
  NONE = "NONE",
  /**
   * Patient indicated that they have an insurance at some other company.
   */
  OTHER = "OTHER"

  // We get other insurance options from backends endpoint (which gets them from CRM)
}

/**
 * Request body for starting the purchase.
 */
export interface AppointmentRequest {
  /**
   * ID of the doctor, e.g. "123".
   *
   * Required for Acute reservation.
   *
   * If the purchase supports Acute reservation, then this must be set.
   * Otherwise, e.g. for `FIT_TEST` and `PSA_TEST`, this must be left out,
   * or set to `undefined` or `null`.
   */
  doctorId: string | undefined | null;
  /**
   * Start time of the desired appointment, e.g. "2021-12-24T08:15:00".
   *
   * Required for Acute reservation.  Also, if method is something other than
   * Hospital, this is used for order number generation.
   *
   * If the purchase supports Acute reservation, then this must be set.
   * Otherwise, e.g. for `FIT_TEST` and `PSA_TEST`, this must be left out,
   * or set to `undefined` or `null`.
   */
  startTime: string | undefined | null;
  /**
   * Duration of the appointment.
   *
   * If reserving an appointment from MyDocrates, this must be as follows:
   *
   *   * COMPREHENSIVE_FIRST_APPOINTMENT: 90
   *   * CONSULTATION_FIRST_APPOINTMENT: 45
   *   * FOLLOWUP_APPOINTMENT_45: 45
   *   * FOLLOWUP_APPOINTMENT_30:
   *
   * Those are the only types that can be reserved from MyDocrates.
   *
   * When starting the payment of an appointment that was made directly to Acute,
   * then the duration must be exactly the number that was given by backend.
   * Otherwise, the `makeAnAppointment` will return `CONFLICT`.
   *
   * If the purchase supports Acute reservation, then this must be set.
   * Otherwise, e.g. for `FIT_TEST` and `PSA_TEST`, this must be left out,
   * or set to `undefined` or `null`.
   */
  duration: number | undefined | null;
  /**
   * Appointment type.
   *
   * Determines the length of the appointment.  Together with method (and
   * currency, which is hardcoded in backend), defines the product (i.e. the
   * price).
   */
  type: AppointmentType;
  /**
   * Contact method.
   *
   * Determines whether to involve Kaiku (method is "Video") and whether to
   * generate payment session (method is something other than "Hospital").
   */
  method: AppointmentMethod;
  /**
   * User selected cancer type.
   *
   * Shown in Acute reservation notes.  Not required on consultation
   * appointments.
   */
  cancerType?: string;
  /**
   * Must be one of the supported locales by the appointments-service.
   */
  locale: SupportedLocale;
  /**
   * Localized product name.
   *
   */
  productName: string;
  /**
   * The insurance company of the patient.
   */
  insuranceCompany: InsuranceCompany;

  /**
   * Numeric enum for the "i have" selection.
   *
   * E.g., "Uusiutunut syöpä" -> some number
   *
   * See Payment.vue for correct numeric values.
   */
  diseasePhase?: number;

  isAcuteReservation?: boolean;

  dhReservationId?: string;
}

/**
 * Response body for booking an appointment.
 */
export interface AppointmentResponse extends BasicHttpBody {
  networkError?: string;
  status?: HttpStatus;

  /**
   * If a Paytrail payment was created for the appointment, then this is set
   * to the Paytrail hosted payment gateway URL.  Frontend must use this URL
   * when opening a new window or tab for the payment.
   */
  paytrailPaymentGatewayUrl?: string;

  /**
   * Appointment ID of the appointment.
   *
   * This is set if {@link status} is `201` (Created).
   */
  appointmentId?: string;
}

/**
 * Response body for DELETE /my/appointments/{appointmentId}
 */
export interface AppointmentCancelResponse extends BasicHttpBody {
  /**
   * Either HTTP status, if any response was received.  Otherwise a network
   * error identifier like "ECONNABORTED" or some such from the OS.
   */
  status: HttpStatus | string;
}
